export class FormData {
  autodatacheck_status: any;
  CaseNumber: any;
  compensation_details_with_other_passenger: any;
  compensation_details: any;
  test: any;
  distance: any;
  work: string = '';
  street: string = '';
  city: string = '';
  state: string = '';
  zip: string = '';
  first_name: string = '';
  last_name: string = '';
  useremail: string = '';
  phone: string = '';
  country: string = '';
  file_upload: any = [];
  file_upload_data: any = [];
  user_title: string = '';
  sign_valu: string = '';
  sign_ar: any = '';
  booking: string = '';
  backclick: boolean = false;
  avi: boolean = false;
  Actual_Arrival_Options: string = '';
  vat_percentage: any;
  varidetails: any;
  flt_dt: string = '';
  flt_num: any = '';
  actual_arrival_time: any = '';
  actual_arrival_date: any = '';
  inform: string = '';
  Who_informed: string = '';
  delay_reason: string = '';
  Denayed_Boarding: string = '';
  delay_reason_details: string = '';
  delay_reason_weather_option: string = '';
  delay_reason_other_option: string = '';
  delay_reason_tech_option: string = '';
  delay_reason_check_in: string = '';
  language: string = '';
  flightDetails: any = '';
  terms_checkbox: boolean = false;
  radio_val: string = '';
  react: any = '';
  Myself_or_None: any = '';
  otherpass: any = '';
  usercurrentdatetime: any = '';
  field_manual_departure_form: any;
  field_manual_scheduled_on: any;
  field_manual_scheduled_time_on: any;
  field_manual_arrival_form: any;
  field_manual_scheduled_arrival_on: any;
  field_manual_scheduled_arrival_time_on: any;

  departure_form: any;
  scheduled_on: any;
  scheduled_time_on: any;
  arrival_form: any;
  arrival_on: any;
  arrival_time_on: any;

  connectingflightsDetails: any;
  manual_entry_without_variflight_from: any;
  manual_entry_without_variflight_airline: any;
  manual_entry_without_variflight_fromdate: any;
  manual_entry_without_variflight_to: any;
  manual_entry_without_variflight_todate: any;
  manual_entry_without_variflight_fromtime: any;
  manual_entry_without_variflight_totime: any;

  rejected_airline: any;

  progressvalue: any;
  backprocessvalue: any;


  clear() {
    this.field_manual_departure_form = '';
    this.field_manual_scheduled_on = '';
    this.field_manual_scheduled_time_on = '';
    this.field_manual_arrival_form = '';
    this.field_manual_scheduled_arrival_on = '';
    this.field_manual_scheduled_arrival_time_on = '';
    this.connectingflightsDetails = '';
    this.actual_arrival_time = '';
    this.actual_arrival_date = '';
    this.inform = '';
    this.Who_informed = '';
    this.delay_reason = '';
    this.delay_reason_details = '';
    this.Denayed_Boarding = '';
    this.compensation_details = '';
    this.work = '';
    this.street = '';
    this.city = '';
    this.state = '';
    this.zip = '';
    this.first_name = '';
    this.last_name = '';
    this.useremail = '';
    this.country = '';
    this.phone = '';
    this.user_title = '';
    this.file_upload = [];
    this.sign_valu = '';
    this.sign_ar;
    this.booking = '';
    this.flightDetails;
    this.terms_checkbox;
    this.radio_val = '';
    this.react = '';
    this.Myself_or_None = '';
    this.otherpass;
    this.varidetails = '';
    this.backprocessvalue = '';
  }
}

export class Personal {
  test: any = '';
  language: string = '';
  flightDetails: any;
  avi: boolean = false;
  field_manual_departure_form: any;
  field_manual_scheduled_on: any;
  field_manual_scheduled_time_on: any;
  field_manual_arrival_form: any;
  field_manual_scheduled_arrival_on: any;
  field_manual_scheduled_arrival_time_on: any;
  Actual_Arrival_Options: string = '';
  flt_num: any;
  flt_dt: string = '';
  varidetails: any;
  departure_form: any;
  scheduled_on: any;
  scheduled_time_on: any;
  arrival_form: any;
  arrival_on: any;
  arrival_time_on: any;
  manual_entry_without_variflight_from: any;
  manual_entry_without_variflight_airline: any
  manual_entry_without_variflight_fromdate: any;
  manual_entry_without_variflight_to: any;
  manual_entry_without_variflight_todate: any;
  manual_entry_without_variflight_fromtime: any;
  manual_entry_without_variflight_totime: any;

  rejected_airline: any;
  /* Arijit Das 24.04..2019 */

  progressvalue: any;

  /* Arijit Das   24.04.2019 */

}
export class Work {
  actual_arrival_time: any;
  actual_arrival_date: any;
  inform: string = '';
  Who_informed: string = '';
  delay_reason: string = '';
  delay_reason_details: string = '';
  Denayed_Boarding: string = '';
  delay_reason_check_in: string = '';
  delay_reason_weather_option: string = '';
  delay_reason_other_option: string = '';
  delay_reason_tech_option: string = '';
  progressvalue: any;
  backprocessvalue: any;
}

export class Address {
  CaseNumber: any;
  distance: any;
  compensation_details_with_other_passenger: any;
  compensation_details: any;
  street: string = '';
  city: string = '';
  state: string = '';
  zip: string = '';
  first_name = '';
  last_name = '';
  useremail = '';
  country = '';
  phone = '';
  user_title = '';
  sign_valu = '';
  sign_ar: any;
  booking: string = '';
  file_upload: any = [];
  file_upload_data: any = [];
  terms_checkbox: boolean = false;
  vat_percentage: any;
  progressvalue: any;
  usercurrentdatetime: any;
  backprocessvalue: any;
  backclick: boolean = false;
}
export class alternetiv {
  react = '';
  Myself_or_None: any;
  progressvalue: any;
}
export class otherpassenger {
  otherpass: any;
}
export class connectingflights {
  connectingflightsDetails: any;
}
