import { NgModule, ErrorHandler, Injectable } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { PersonalComponent } from "./personal/personal.component";
import { WorkComponent } from "./work/work.component";
import { AddressComponent } from "./address/address.component";
import { ResultComponent } from "./result/result.component";
import { AppRoutingModule } from "./app-routing.module";
import { FormDataService } from "./data/formData.service";
import { WorkflowService } from "./workflow/workflow.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { AlternetivTransportComponent } from "./alternetiv-transport/alternetiv-transport.component";
import { OtherPassengerComponent } from "./other-passenger/other-passenger.component";
// import { AngularFileUploaderModule } from 'angular-file-uploader';
import { SignaturePadModule } from "angular2-signaturepad";
import { ConnectingFlightsComponent } from "./connecting-flights/connecting-flights.component";
import { DatePipe } from "@angular/common";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatButtonModule } from "@angular/material/button";

import { MatInputModule } from "@angular/material/input";

import { MatFormFieldModule } from "@angular/material/form-field";

import { MatRippleModule } from "@angular/material/core";

import { HaversineService } from "ng2-haversine";

import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { RejectClaimComponent } from "./reject-claim/reject-claim.component";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { NgxSpinnerModule } from "ngx-spinner";
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from "ngx-ui-loader";
import { FooterSectionComponent } from "./footer-section/footer-section.component";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatAutocompleteModule } from "@angular/material/autocomplete";

import * as Sentry from "@sentry/browser";
import { SignatureComponent } from "./signature/signature.component";
import { environment } from "../../src/environments/environment";

let service_url = environment.API_BASE_URL;
if (service_url == "http://localhost:4200") {
  // local build
  // Sentry.init({
  //   dsn: 'https://9e27897b648a42d39bd106f1d8f703d4@sentry.io/1891195',
  // });
} else {
  let form_url = window.location.hostname;
  let form_url_split = form_url.split(".claimflights.");
  if (
    form_url_split[0] == "forms" ||
    form_url_split[0] == "bgforms" ||
    form_url_split[0] == "esforms"
  ) {
    Sentry.init({
      // dsn: "https://11d7fe79290b48e49b62c1bd438eb03e@sentry.io/1879229",
      dsn: "https://d57c821b594e3a00a1254fdba8e8e6dd@o340279.ingest.sentry.io/4505905318985728"
    });
    console.log("form/bgform-Procuction-Build-Sentry");
  } else if (
    form_url_split[0] == "devforms" ||
    form_url_split[0] == "liveforms" ||
    form_url_split[0] == "testing"
  ) {
    Sentry.init({
      dsn: "https://9e27897b648a42d39bd106f1d8f703d4@sentry.io/1891195",
    });
    console.log("devform-Development-Build-Sentry");
  }
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    // const eventId = Sentry.captureException(error.originalError || error);
    const eventId = Sentry.captureException(error.error || error.headers || error.message || error.name || error.ok || error || error.originalError);
    //Sentry.showReportDialog({ eventId });
    Sentry.init({
      ignoreErrors: ["Non-Error exception captured"],
    });
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "red",
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.rectangleBounce,
  fgsType: SPINNER.chasingDots,
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 5,
};

@NgModule({
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    MatAutocompleteModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SignaturePadModule,
    BrowserAnimationsModule,
    // AngularFileUploaderModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRippleModule,
    MatInputModule,
    MatButtonModule,
    AutocompleteLibModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),

    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
  ],
  providers: [
    { provide: FormDataService, useClass: FormDataService },
    { provide: WorkflowService, useClass: WorkflowService },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    DatePipe,
    HaversineService,
  ],
  declarations: [
    AppComponent,
    NavbarComponent,
    PersonalComponent,
    WorkComponent,
    AddressComponent,
    ResultComponent,
    AlternetivTransportComponent,
    OtherPassengerComponent,
    ConnectingFlightsComponent,
    RejectClaimComponent,
    FooterSectionComponent,
    SignatureComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
