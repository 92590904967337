import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { PersonalComponent } from "./personal/personal.component";
import { WorkComponent } from "./work/work.component";
import { AddressComponent } from "./address/address.component";
import { ResultComponent } from "./result/result.component";
import { RejectClaimComponent } from "./reject-claim/reject-claim.component";

import { WorkflowGuard } from "./workflow/workflow-guard.service";
import { WorkflowService } from "./workflow/workflow.service";
import { SignatureComponent } from "./signature/signature.component";

export const appRoutes: Routes = [
  { path: "Problem", component: WorkComponent, canActivate: [WorkflowGuard] },
  {
    path: "address",
    component: AddressComponent,
    canActivate: [WorkflowGuard],
  },
  { path: "signature", component: SignatureComponent },
  { path: "finish", component: ResultComponent, canActivate: [WorkflowGuard] },
  { path: "reject-claim", component: RejectClaimComponent },
  { path: "enter_flight_details", component: PersonalComponent },
  { path: "", redirectTo: "/enter_flight_details", pathMatch: "full" },
  { path: "**", redirectTo: "/enter_flight_details" },
  { path: "enter_flight_details", redirectTo: "/enter_flight_details" },



  // {
  //   path: '',
  //   loadChildren: () => import('./enter-your-flights/enter-your-flights.module').then(m => m.EnterYourFlightsModule)
  // },
  // {
  //   path: '**',
  //   loadChildren: () => import('./enter-your-flights/enter-your-flights.module').then(m => m.EnterYourFlightsModule)
  // },
  {
    path: 'enter_flight_detailsnew',
    loadChildren: () => import('./enter-your-flights/enter-your-flights.module').then(m => m.EnterYourFlightsModule)
  },
  // {
  //   path: 'Problem',
  //   loadChildren: () => import('./prob/prob.module').then(m => m.ProbModule)
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: false })],
  exports: [RouterModule],
  providers: [WorkflowGuard],
})
export class AppRoutingModule {}
