import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SignaturePad } from "angular2-signaturepad/signature-pad";
import { Address, FormData } from "../data/formData.model";
import { FormDataService } from "../data/formData.service";
import { UserService } from "../user.service";
import { empty } from "rxjs";
import { HaversineService, GeoCoord } from "ng2-haversine";
import { formatDate } from "@angular/common";
import { NgxUiLoaderService } from "ngx-ui-loader";

import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";

import { TranslateService } from "@ngx-translate/core";
// import { analyzeAndValidateNgModules } from "@angular/compiler";
import { PlatformLocation } from "@angular/common";
import { url } from "inspector";
import { environment } from "../../../src/environments/environment";

@Component({
  selector: "mt-wizard-address",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.css"],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
})
export class AddressComponent implements OnInit {
  title =
    "PLEASE ENTER YOUR PERSONAL DATA SO THAT WE CAN CLAIM YOUR COMPENSATION";
  address: Address;
  public formdata: FormData;
  form: any;
  marked = false;
  theCheckbox = false;
  sign_value: any;
  sign_ar_v: any;
  amount: any;
  files: any;
  filestring: any;
  public vat_percent: any;
  public distance: any;
  public OTHER: boolean = false;
  public BTNOTHER: boolean = false;
  public delete: boolean = true;
  public showalt1: boolean = false;
  public amount_details: boolean = true;
  public user_input: boolean = false;
  public _signature: any = null;
  public file_upload_booking_ref: boolean = true;
  public confirm_pdf: boolean = false;
  public signature: boolean = true;
  public propagateChange: Function = null;
  public lan: any;
  public kilometers: any;
  public main_amount: any;
  public Alternative_transport_distance_cal: any;
  public insignpage: boolean = false;
  response: any;
  public succ_per: any;
  FlightArrtimePlanDate2: any;
  public eu_sts_arr_airport: any;
  public distance_in_miles: any;
  public price_calculation_details: any;
  public amount_check: any;
  public service_url = environment.API_BASE_URL;
  public selectedFileCount = 0;
  public fileloader = null;

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  submitClicked: boolean=false;
  fileError: boolean;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private formDataService: FormDataService,
    private userservice: UserService,
    private _haversineService: HaversineService,
    public location: PlatformLocation,
    private ngxService: NgxUiLoaderService
  ) {
    this.address = this.formDataService.getAddress();
    this.formdata = this.formDataService.getFormData();
    this.formDataService.setAddress(this.address);
    console.log(this.formdata);
    
    this.lan = this.formdata.language;
    translate.setDefaultLang(this.lan);
    if (this.insignpage == true) {
      location.onPopState(() => {
        let Confirmbox = confirm("Do you want to leave the process?");
        if ((Confirmbox = true)) {
        }
      });
    }
    // if (this.service_url == "http://localhost:4200") {
    //   this.service_url = "https://devforms.claimflights.com";
    // }
    
  }

  ADD_OTHER_PASS() {
    if (!this.OTHER) {
      this.OTHER = !this.OTHER;
      this.OTHER = true;
      this.BTNOTHER = true;
      this.showalt1 = true;
      this.delete = false;
    } else {
    }
  }
  DELETE_OTHER_PASENGER() {
    if (this.OTHER) {
      this.OTHER = !this.OTHER;
      this.OTHER = false;
      this.BTNOTHER = false;
      this.delete = true;
      this.showalt1 = false;
    } else {
    }
  }
  ngOnInit() {
    this.loadScript2(
      "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
    );
    $("#fileloader").change(function () {
      // var filename = this.fileloader[0].name
    });

    if (this.formdata.connectingflightsDetails != null) {
      let connec_flt_arr_length =
        this.formdata.connectingflightsDetails.length - 1;
      this.eu_sts_arr_airport = this.formdata.connectingflightsDetails[
        connec_flt_arr_length
      ].connecting_flight_all_Details.arr_in_eu;
    } else {
      this.eu_sts_arr_airport = this.formdata.flightDetails.arr_in_eu;
    }
    let eu_o = this.eu_sts_arr_airport;
    let eu_d = this.formdata.flightDetails.dep_in_eu;
    this.address = this.formDataService.getAddress();
    this.formdata = this.formDataService.getFormData();
    let inportantname = this.formdata.Actual_Arrival_Options;
    if (inportantname == "") {
      window.location.href = "/enter_flight_details";
    }
    this.main_amount = this.distance_cal_of_main_flt();
    if (this.formdata.connectingflightsDetails != null) {
      this.main_amount = this.main_amount_and_distance_cal_for_connecting();
    }
    let arrivaldetails = this.formdata.Actual_Arrival_Options;
    let reactdetails = this.formdata.react;
    if (
      arrivaldetails == "My_flight_was_delayed" ||
      arrivaldetails == "I_landed_on_time"
    ) {
      this.main_amount = this.distance_cal_of_main_flt();
      if (this.formdata.connectingflightsDetails != null) {
        this.main_amount = this.main_amount_and_distance_cal_for_connecting();
      }
    } else {
      if (
        this.formdata.Myself_or_None == "None_Provided" ||
        this.formdata.Myself_or_None == "I_Did_It_Myself"
      ) {
        this.main_amount = this.distance_cal_of_main_flt();
        if (this.formdata.connectingflightsDetails != null) {
          this.main_amount = this.main_amount_and_distance_cal_for_connecting();
        }
      } else {
        let altlenth1 = this.formdata.react.length - 1;
        if (this.formdata.connectingflightsDetails != null) {
          let last_connecting_1 =
            this.formdata.connectingflightsDetails.length - 1;
          if (
            this.formdata.react[0].transport_details.FlightDepAirport ==
            this.formdata.flightDetails.FlightDepAirport &&
            this.formdata.react[altlenth1].transport_details.FlightArrAirport ==
            this.formdata.connectingflightsDetails[last_connecting_1]
              .connecting_flight_all_Details.FlightArrAirport
          ) {
            this.main_amount = this.Alternative_transport_distance_calculate();
          } else if (
            this.formdata.react[0].transport_details.FlightDepAirport ==
            this.formdata.flightDetails.FlightDep
          ) {
            this.main_amount = this.Alternative_transport_distance_calculate();
          } else if (
            this.formdata.react[0].transport_details.FlightDepAirport ==
            this.formdata.connectingflightsDetails[0]
              .connecting_flight_all_Details.FlightDepAirport
          ) {
            this.main_amount = this.Alternative_transport_distance_calculate();
          }
        } else {
          if (
            this.formdata.react[0].transport_details.FlightDep ==
            this.formdata.flightDetails.FlightDep
          ) {
            this.main_amount = this.Alternative_transport_distance_calculate();
          }
        }
      }
    }
    if (this.formdata.language == "co.uk") {
      this.vat_percent = 20;
      this.succ_per = 25;
    } else if (this.formdata.language == "de") {
      this.vat_percent = 19;
      this.succ_per = 22.5;
    } else if (this.formdata.language == "BG") {
      this.vat_percent = 20;
      this.succ_per = 20.75;
    } else if (this.formdata.language == "fr") {
      this.vat_percent = 20;
      this.succ_per = 25;
    } else if (this.formdata.language == "pl") {
      this.vat_percent = 23;
      this.succ_per = 20.25;
    } else if (this.formdata.language == "ro") {
      this.vat_percent = 19;
      this.succ_per = 21;
    } else if (this.formdata.language == "it") {
      this.vat_percent = 22;
      // this.succ_per = 22.5;
      this.succ_per = 25;
    } else if (this.formdata.language == "es") {
      this.vat_percent = 21;
      this.succ_per = 25;
    } else if (this.formdata.language == "ru") {
      this.vat_percent = 0;
      this.succ_per = 25;
    } else if (this.formdata.language == "at") {
      this.vat_percent = 20;
      this.succ_per = 25;
    } else if (this.formdata.language == "com") {
      this.vat_percent = 0;
      this.succ_per = 25;
    } else {
      this.vat_percent = 0;
    }
    if (this.vat_percent != "") {
      this.vat_percent = this.vat_percent;
    } else {
      this.vat_percent = "0";
    }
    let success_percentage = this.succ_per;
    let vat_percentage_amount = this.vat_percent;

    let success_amount = (success_percentage * this.main_amount) / 100;
    let vat_amount = (vat_percentage_amount * success_amount) / 100;
    let total_deduct_amount = success_amount + vat_amount;
    let total_refund = this.main_amount - total_deduct_amount;

    const arr: Array<{
      total_refund: any;
      refund_amount: any;
      total_deduct_amount: any;
      success_amount: any;
      vat_amount: any;
      vat_percentage: any;
      success_percentage: any;
    }> = [
        {
          total_refund: total_refund,
          refund_amount: this.main_amount,
          total_deduct_amount: total_deduct_amount,
          success_amount: success_amount,
          vat_amount: vat_amount,
          vat_percentage: vat_percentage_amount + "%",
          success_percentage: success_percentage + "%",
        },
      ];
      console.log(arr);

    this.price_calculation_details = arr;
    this.address.vat_percentage = vat_percentage_amount;
    this.address.compensation_details = this.price_calculation_details;
    console.log(this.address.compensation_details);
    console.log(this.price_calculation_details);
    this.formDataService.setAddress(this.address);
    this.amount_check = this.compensation_amount_nullcheck();
  }

  resetFileCount () {
    this.selectedFileCount = 0;
  }

  save(form: any): boolean {
    if (!form.valid) {
      return false;
    }

    this.formDataService.setAddress(this.address);
    return true;
  }

  goToPrevious(form: any) {
    let valuechecktype = "4";
    this.address.backprocessvalue = valuechecktype;
    this.formDataService.setAddress(this.address);
    this.location.back();
  }

  user_details() {
    this.amount_details = !this.amount_details;
  }
  goToNextlide() {
    console.log(this.submitClicked);
    this.submitClicked = true
    this.ngxService.start();
    this.formdata.backclick = false;
    let secondstepval = "4page3step";
    this.address.progressvalue = secondstepval;
    this.formDataService.setAddress(this.address);
    this.user_input = true;
    this.file_upload_booking_ref = false;
    this.formDataService.setAddress(this.address);

    if (this.formdata.country == "GB") {
      this.vat_percent = 20;
    } else if (this.formdata.country == "DE") {
      this.vat_percent = 19;
    } else if (this.formdata.country == "US") {
      this.vat_percent = 0;
    } else if (this.formdata.country == "AT") {
      this.vat_percent = 20;
    } else if (this.formdata.country == "CY") {
      this.vat_percent = 19;
    } else if (this.formdata.country == "FR") {
      this.vat_percent = 20;
    } else if (this.formdata.country == "PL") {
      this.vat_percent = 23;
    } else if (this.formdata.country == "IT") {
      this.vat_percent = 22;
    } else if (this.formdata.country == "DK") {
      this.vat_percent = 25;
    } else if (this.formdata.country == "FI") {
      this.vat_percent = 24;
    } else if (this.formdata.country == "GR") {
      this.vat_percent = 24;
    } else if (this.formdata.country == "HU") {
      this.vat_percent = 27;
    } else if (this.formdata.country == "LV") {
      this.vat_percent = 21;
    } else if (this.formdata.country == "LT") {
      this.vat_percent = 21;
    } else if (this.formdata.country == "LU") {
      this.vat_percent = 17;
    } else if (this.formdata.country == "MT") {
      this.vat_percent = 18;
    } else if (this.formdata.country == "NL") {
      this.vat_percent = 21;
    } else if (this.formdata.country == "SK") {
      this.vat_percent = 20;
    } else if (this.formdata.country == "SI") {
      this.vat_percent = 22;
    } else if (this.formdata.country == "ES") {
      this.vat_percent = 21;
    } else if (this.formdata.country == "SE") {
      this.vat_percent = 25;
    } else if (this.formdata.country == "BE") {
      this.vat_percent = 21;
    } else if (this.formdata.country == "BG") {
      this.vat_percent = 20;
    } else if (this.formdata.country == "HR") {
      this.vat_percent = 25;
    } else if (this.formdata.country == "CZ") {
      this.vat_percent = 21;
    } else if (this.formdata.country == "EE") {
      this.vat_percent = 20;
    } else if (this.formdata.country == "IE") {
      this.vat_percent = 23;
    } else if (this.formdata.country == "PT") {
      this.vat_percent = 23;
    } else if (this.formdata.country == "RO") {
      this.vat_percent = 19;
    } else {
      this.vat_percent = 0;
    }
    let success_percentage = this.succ_per;
    if (this.vat_percent != "") {
      this.vat_percent = this.vat_percent;
    } else {
      this.vat_percent = "0";
    }

    let vat_percentage_amount = this.vat_percent;
    let success_amount = (success_percentage * this.main_amount) / 100;
    let vat_amount = (vat_percentage_amount * success_amount) / 100;
    let total_deduct_amount = success_amount + vat_amount;
    let total_refund = this.main_amount - total_deduct_amount;

    const arr: Array<{
      total_refund: any;
      refund_amount: any;
      total_deduct_amount: any;
      success_amount: any;
      vat_amount: any;
      vat_percentage: any;
      success_percentage: any;
    }> = [
        {
          total_refund: total_refund,
          refund_amount: this.main_amount,
          total_deduct_amount: total_deduct_amount,
          success_amount: success_amount,
          vat_amount: vat_amount,
          vat_percentage: vat_percentage_amount + "%",
          success_percentage: success_percentage + "%",
        },
      ];

    if (this.formdata.otherpass != null) {
      let otherpas_arr_lenth = this.formdata.otherpass.length + 1;
      let otherpas_main_amount = this.main_amount * otherpas_arr_lenth;
      let vat_percentage_amount = this.vat_percent;
      let otherpas_success_amount =
        (success_percentage * otherpas_main_amount) / 100;
      let otherpas_vat_amount =
        (vat_percentage_amount * otherpas_success_amount) / 100;
      let otherpas_total_deduct_amount =
        otherpas_success_amount + otherpas_vat_amount;
      let otherpas_total_refund =
        otherpas_main_amount - otherpas_total_deduct_amount;

      const otherpas_arr: Array<{
        otherpas_total_refund: any;
        otherpas_refund_amount: any;
        otherpas_total_deduct_amount: any;
        otherpas_success_amount: any;
        otherpas_vat_amount: any;
        vat_percentage: any;
        success_percentage: any;
      }> = [
          {
            otherpas_total_refund: otherpas_total_refund,
            otherpas_refund_amount: otherpas_main_amount,
            otherpas_total_deduct_amount: otherpas_total_deduct_amount,
            otherpas_success_amount: otherpas_success_amount,
            otherpas_vat_amount: otherpas_vat_amount,
            vat_percentage: vat_percentage_amount + "%",
            success_percentage: success_percentage + "%",
          },
        ];
      this.address.compensation_details_with_other_passenger = otherpas_arr;
    }
    console.log(this.address.compensation_details);
    console.log(this.price_calculation_details);
    
    this.price_calculation_details = arr;
    this.address.vat_percentage = vat_percentage_amount;
    this.address.compensation_details = this.price_calculation_details;
    this.formDataService.setAddress(this.address);
    let today = new Date();
    let jstoday = "";
    jstoday = formatDate(today, "dd.MM.yyyy hh:mm:ss a", "en-US", "+0530");
    this.address.usercurrentdatetime = jstoday;
    this.formDataService.setAddress(this.address);

    let z = this.service_url + "claim_record.php";

    this.userservice.For_Formdata(this.formdata, z).subscribe((response) => {
      this.response = response;
      this.address.CaseNumber = this.response[0].CaseNumber;
      this.formDataService.setAddress(this.address);
      this.ngxService.stop();
      console.log('signature');
      
      this.router.navigate(["/signature"]);
    });
  }

  urls = [];
  full_datas = [];
  onSelectFile(event) {    
    this.fileError = false;
    if (event.target.files && event.target.files[0]) {
      if (event.target.files.length <= "5") {
        let count = 0
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          this.full_datas[i] = event.target.files[i];
          this.address.file_upload[i] = this.full_datas[i];
          this.formDataService.setAddress(this.address);
          var reader = new FileReader();
          reader.onload = (event: any) => {
    console.log(event.target.files);

            this.urls.push(event.target.result);
            this.address.file_upload_data = this.urls;
            this.formDataService.setAddress(this.address);
          };
          reader.readAsDataURL(event.target.files[i]);
          if((event.target.files[i].type == 'application/pdf' 
          || event.target.files[i].type == 'image/png' 
          || event.target.files[i].type == 'image/jpg'
          || event.target.files[i].type == 'image/JPG'
          || event.target.files[i].type == 'image/JPEG' 
          || event.target.files[i].type == 'image/jpeg'))
        {
          if(event.target.files[i].size <= 1500000){
          // if(event.target.files[i].size <= 500000){
            count ++;
            console.log(count);
            
            if(count == event.target.files.length){this.fileError = true;}
          }
        }
        }
        this.confirm_pdf = true;
      } else {
      }
      this.selectedFileCount = event.target.files.length;
    }
    console.log(this.address.file_upload);
    console.log(this.fileError);
    
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.filestring = btoa(binaryString);
    this.address.file_upload_data = this.filestring;
    this.formDataService.setAddress(this.address);
  }

  delet_pdf_file() {
    this.confirm_pdf = false;
    this.urls = [];
    this.full_datas = [];
    this.address.file_upload = [];
    this.address.file_upload_data = [];
    this.fileError = false;
    this.formDataService.setAddress(this.address);
  }
  afuConfig = {
    multiple: false,
    formatsAllowed: ".jpg,.png",
    maxSize: "1",
    uploadAPI: {
      url: "http://localhost/example/claimflights/fileupload.php",
    },
  };

  checkbox_val() {
    if (this.address.terms_checkbox == true) {
      this.address.terms_checkbox = true;
      this.formDataService.setAddress(this.address);
    }
    if (this.address.terms_checkbox == false) {
      this.address.terms_checkbox = false;
      this.formDataService.setAddress(this.address);
    }
  }

  goToNext(form: any) {
    this.ngxService.start();
    this.insignpage = false;
    if (this.save(form)) {
      let z = this.service_url + "claim_record.php";
      this.userservice.For_Formdata(this.formdata, z).subscribe((response) => {
        this.response = response;
        if (this.response == null) {
          this.ngxService.stop();
        }
      });
      this.router.navigate(["/finish"]);
    }
  }
  backFromBooking() {
    this.file_upload_booking_ref = true;
    this.user_input = false;
    this.address.backclick = true;
    this.address.CaseNumber = null;
    this.formDataService.setAddress(this.address);
  }

  amount_compensetion(distance, amount) {
    if (this.formdata.connectingflightsDetails != null) {
      let connec_flt_arr_length =
        this.formdata.connectingflightsDetails.length - 1;
      this.eu_sts_arr_airport = this.formdata.connectingflightsDetails[
        connec_flt_arr_length
      ].connecting_flight_all_Details.arr_in_eu;
    } else {
      this.eu_sts_arr_airport = this.formdata.flightDetails.arr_in_eu;
    }
    let eu_o = this.eu_sts_arr_airport;
    let eu_d = this.formdata.flightDetails.dep_in_eu;
    if (eu_o == 0 && eu_d == 0) {
      this.router.navigate(["/reject-claim"]);
    }
    if (eu_o == 1 && eu_d == 1) {
      if (distance <= "1500") {
        amount = 250;
      } else {
        amount = 400;
      }
      return amount;
    }
    if (distance <= "1500") {
      amount = 250;
    } else if (distance > "1501" && distance <= "3500") {
      amount = 400;
    } else if (distance > "3500") {
      amount = 600;
    } else {
      amount = 0;
    }
    return amount;
  }

  Alternative_transport_distance_calculate() {
    let alt_arry = this.formdata.react.length - 1;
    if (this.formdata.react.length != null) {
      let total_delayed_time = this.alt_delay_calculate();
      let alter_amount = this.for_alternative_amount(
        this.distance,
        this.amount,
        total_delayed_time
      );
      return alter_amount;
    }
  }
  for_alternative_amount(distance, amount, total_delayed_time) {
    if (this.formdata.connectingflightsDetails != null) {
      let connec_flt_arr_length =
        this.formdata.connectingflightsDetails.length - 1;
      this.eu_sts_arr_airport = this.formdata.connectingflightsDetails[
        connec_flt_arr_length
      ].connecting_flight_all_Details.arr_in_eu;
    } else {
      this.eu_sts_arr_airport = this.formdata.flightDetails.arr_in_eu;
    }
    let eu_o = this.eu_sts_arr_airport;
    let eu_d = this.formdata.flightDetails.dep_in_eu;
    if (eu_o == 1 && eu_d == 1) {
      if (distance <= 1500) {
        if (total_delayed_time <= 120) {
          amount = 125;
        } else {
          amount = 250;
        }
      } else if (distance > 1501 && distance <= 3500) {
        if (total_delayed_time < 180) {
          amount = 200;
        } else {
          amount = 400;
        }
      } else {
        if (total_delayed_time < 240) {
          amount = 300;
        } else {
          amount = 400;
        }
      }
      return amount;
    }

    if (distance <= 1500) {
      if (total_delayed_time <= 120) {
        amount = 125;
      } else {
        amount = 250;
      }
    } else if (distance > 1501 && distance <= 3500) {
      if (total_delayed_time < 180) {
        amount = 200;
      } else {
        amount = 400;
      }
    } else {
      if (total_delayed_time < 240) {
        amount = 300;
      } else {
        amount = 600;
      }
    }
    return amount;
  }

  loadScript2(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement("script");
    script.innerHTML = "";
    script.src = url;
    body.appendChild(script);
  }
  main_amount_and_distance_cal_for_connecting() {
    let last_connecting_flight =
      this.formdata.connectingflightsDetails.length - 1;
    let dep_airport: GeoCoord = {
      latitude: this.formdata.flightDetails.depparture_airport_Latitude,
      longitude: this.formdata.flightDetails.depparture_airport_Longitude,
    };

    let arri_airport: GeoCoord = {
      latitude: this.formdata.connectingflightsDetails[last_connecting_flight]
        .connecting_flight_all_Details.arrival_airport_Latitude,
      longitude: this.formdata.connectingflightsDetails[last_connecting_flight]
        .connecting_flight_all_Details.arrival_airport_Longitude,
    };

    this.kilometers = this._haversineService.getDistanceInKilometers(
      dep_airport,
      arri_airport
    );

    this.distance = this.kilometers;
    this.address.distance = this.distance;
    this.formDataService.setAddress(this.address);
    this.main_amount = this.amount_compensetion(this.distance, this.amount);
    return this.main_amount;
  }
  main_amount_and_distance_cal_for_connecting_v2() {
    let last_connecting_flight =
      this.formdata.connectingflightsDetails.length - 1;
    let dep_airport: GeoCoord = {
      latitude: this.formdata.connectingflightsDetails[0]
        .connecting_flight_all_Details.arrival_airport_Latitude,
      longitude: this.formdata.connectingflightsDetails[last_connecting_flight]
        .connecting_flight_all_Details.arrival_airport_Longitude,
    };

    let arri_airport: GeoCoord = {
      latitude: this.formdata.connectingflightsDetails[last_connecting_flight]
        .connecting_flight_all_Details.arrival_airport_Latitude,
      longitude: this.formdata.connectingflightsDetails[last_connecting_flight]
        .connecting_flight_all_Details.arrival_airport_Longitude,
    };

    this.kilometers = this._haversineService.getDistanceInKilometers(
      dep_airport,
      arri_airport
    );
    this.distance = this.kilometers;
    this.main_amount = this.amount_compensetion(this.distance, this.amount);
    return this.main_amount;
  }
  distance_cal_of_main_flt() {
    let dep_airport: GeoCoord = {
      latitude: this.formdata.flightDetails.depparture_airport_Latitude,
      longitude: this.formdata.flightDetails.depparture_airport_Longitude,
    };

    let arri_airport: GeoCoord = {
      latitude: this.formdata.flightDetails.arrival_airport_Latitude,
      longitude: this.formdata.flightDetails.arrival_airport_Longitude,
    };

    let meters = this._haversineService.getDistanceInMeters(
      dep_airport,
      arri_airport
    );
    this.kilometers = this._haversineService.getDistanceInKilometers(
      dep_airport,
      arri_airport
    );
    this.distance_in_miles = this._haversineService.getDistanceInMiles(
      dep_airport,
      arri_airport
    );
    this.formDataService.setAddress(this.address);
    this.distance = this.kilometers;
    this.address.distance = this.distance;
    this.formDataService.setAddress(this.address);
    this.main_amount = this.amount_compensetion(this.distance, this.amount);
    return this.main_amount;
  }
  alt_delay_calculate() {
    let altarrlenth = this.formdata.react.length - 1;
    let FlightArrtimePlanDate1 = this.formdata.react[altarrlenth]
      .transport_details.actual_arrival;
    if (this.formdata.connectingflightsDetails != null) {
      let last_connecting_flight =
        this.formdata.connectingflightsDetails.length - 1;
      this.FlightArrtimePlanDate2 = this.formdata.connectingflightsDetails[
        last_connecting_flight
      ].connecting_flight_all_Details.scheduled_arrival;
    }
    this.FlightArrtimePlanDate2 = this.formdata.flightDetails.scheduled_arrival;
    if (FlightArrtimePlanDate1 == "") {
      this.router.navigate(["/reject-claim"]);
    } else {
      let diffInMs: number =
        Date.parse(FlightArrtimePlanDate1) -
        Date.parse(this.FlightArrtimePlanDate2);
      let diffinminits: number = diffInMs / 1000 / 60;
    }
  }

  compensation_amount_nullcheck() {
    if (this.formdata.compensation_details[0].refund_amount == null) {
      window.location.href = "/enter_flight_details";
    }
  }
}
