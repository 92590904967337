import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, FormControl } from "@angular/forms";
import { Validators } from "@angular/forms";
import { UntypedFormArray } from "@angular/forms";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { alternetiv, FormData } from "../data/formData.model";
import { FormDataService } from "../data/formData.service";
import { HttpClient } from "@angular/common/http";
import { UserService } from "../user.service";
import { DatePipe } from "@angular/common";
import {
  NativeDateAdapter,
  DateAdapter,
  MAT_DATE_FORMATS
} from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "../date.adapter";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../src/environments/environment";

@Component({
  selector: "app-alternetiv-transport",
  templateUrl: "./alternetiv-transport.component.html",
  styleUrls: ["./alternetiv-transport.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class AlternetivTransportComponent implements OnInit {
  startDate = new Date();
  alternetive: alternetiv;
  formdata: FormData;
  public myForm: UntypedFormGroup;
  public controls: any;
  public FormControl: any;
  public attributes: any;
  public lan: any;
  response: any;
  public Autocomplete_json: any;
  public countries: any;
  public indexnew: any;
  public service_url = environment.API_BASE_URL;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private _fb: UntypedFormBuilder,
    private formDataService: FormDataService,
    private svc: UserService,
    private http: HttpClient,
    private datePipe: DatePipe
  ) {
    this.alternetive = this.formDataService.getalternetiv();
    this.formdata = this.formDataService.getFormData();
    this.formDataService.setalternetiv(this.alternetive);
    this.lan = this.formdata.language;
    translate.setDefaultLang(this.lan);
    // if (this.service_url == "http://localhost:4200") {
    //   this.service_url = "https://devforms.claimflights.com";
    // }
  }

  ngOnInit() {
    this.alternetive = this.formDataService.getalternetiv();
    this.myForm = this._fb.group({
      languages: this._fb.array([this.initlanguage()]),
    });

    if (this.alternetive.react) {
      if (this.alternetive.react.length) {
        if (this.alternetive.react.length - 1 == -1) {
        } else {
          this.indexnew = this.alternetive.react.length - 1;
        }
      }
    }
  }
  initlanguage() {
    return this._fb.group({
      React: [""],
      flightnumber: [""],
      flightdate: [""],
      fromdair: [""],
      dapdatetrain: [""],
      deptimetrain: [""],
      fromaair: [""],
      arvdatetrain: [""],
      arvtimetrain: [""],
      frombybus: [""],
      datebybus: [""],
      timebybus: [""],
      tobybus: [""],
      arrdatebybus: [""],
      arrtimebybus: [""],
      transport_details: [""],
    });
  }

  Myself_or_None(val: any) {
    if (val == "None_Provided") {
      this.alternetive.Myself_or_None = "None_Provided";
    }
    if (val == "I_Did_It_Myself") {
      this.alternetive.Myself_or_None = "I_Did_It_Myself";
    }
    let thiredstepval = "4page1step";
    this.alternetive.progressvalue = thiredstepval;
    this.formDataService.setalternetiv(this.alternetive);
    this.router.navigate(["/address"]);
  }

  addLanguage() {
    const control = <UntypedFormArray>this.myForm.controls["languages"];
    control.push(this.initlanguage());
    this.indexnew = this.alternetive.react.length - 1;
  }

  removeLanguage(i: number) {
    const control = <UntypedFormArray>this.myForm.controls["languages"];
    control.removeAt(i);
  }

  save() {
    this.alternetive.react = this.myForm.value.languages;
    this.formDataService.setalternetiv(this.alternetive);
  }

  plane() {}

  nxt_page(form: any) {
    this.alternetive.react = this.myForm.value.languages;
    this.formDataService.setalternetiv(this.alternetive);
    if (this.alternetive.react != null) {
      let index = this.alternetive.react.length - 1;
      for (let w = 0; w <= index; w++) {
        if (this.formdata.react[w].React == 1) {
          let x = this.formdata.react[w].flightnumber.replace(/\s/g, "");
          let y = this.datePipe.transform(
            this.formdata.react[w].flightdate,
            "dd.MM.yyyy"
          );
          let z =
            this.service_url +
            "api/v1/variflight/?api_key=cf-2019-1228-9c7b&flightNo=" +
            x +
            "&flightDate=" +
            y;
          this.svc
            .For_alternativeflights(this.alternetive, z)
            .subscribe((response) => {
              this.response = response;
              if (this.response == null) {
              } else {
                this.formdata.react[w].transport_details = this.response;
              }
            });
        } else if (this.formdata.react[w].React == 2) {
          let dep_airport = this.formdata.react[w].fromdair;
          let arr_airport = this.formdata.react[w].fromaair;
          let dep_date = this.formdata.react[w].dapdatetrain;
          let dep_time = this.formdata.react[w].deptimetrain;
          let arr_date = this.formdata.react[w].arvdatetrain;
          let arrv_time = this.formdata.react[w].arvtimetrain;

          let dep_time_split_hour =
            this.formdata.react[w].deptimetrain.hour.toString().length < 2
              ? "0" + this.formdata.react[w].deptimetrain.hour.toString()
              : this.formdata.react[w].deptimetrain.hour;

          let dep_time_split_minute =
            this.formdata.react[w].deptimetrain.minute.toString().length < 2
              ? "0" + this.formdata.react[w].deptimetrain.minute.toString()
              : this.formdata.react[w].deptimetrain.minute;

          let arrv_time_split_hour =
            this.formdata.react[w].arvtimetrain.hour.toString().length < 2
              ? "0" + this.formdata.react[w].arvtimetrain.hour.toString()
              : this.formdata.react[w].arvtimetrain.hour;

          let arrv_time_split_minute =
            this.formdata.react[w].arvtimetrain.minute.toString().length < 2
              ? "0" + this.formdata.react[w].arvtimetrain.minute.toString()
              : this.formdata.react[w].arvtimetrain.minute;

          let dep_time_split =
            dep_time_split_hour + ":" + dep_time_split_minute;
          let arrv_time_split =
            arrv_time_split_hour + ":" + arrv_time_split_minute;

          let n = this.datePipe.transform(dep_date, "dd.MM.yyyy");
          let m = this.datePipe.transform(arr_date, "dd.MM.yyyy");

          let r =
            this.service_url +
            "train_bus.php?api_key=cf-2019-1228-9c7b&dep_airport=" +
            dep_airport.iata_code +
            "&arr_airport=" +
            arr_airport.iata_code +
            "&dep_date=" +
            n +
            "&arr_date=" +
            m +
            "&dep_time=" +
            dep_time_split +
            "&arr_time=" +
            arrv_time_split;
          this.svc
            .For_alternativebustrain(this.alternetive, r)
            .subscribe((response) => {
              this.response = response;
              if (this.response == null) {
              } else {
                this.formdata.react[w].transport_details = this.response;
              }
            });
        }
      }
    }
    let thiredstepval = "4page1step";
    this.alternetive.progressvalue = thiredstepval;
    this.formDataService.setalternetiv(this.alternetive);
    this.alternetive.react = this.myForm.value.languages;
    this.formDataService.setalternetiv(this.alternetive);
    setTimeout(() => {
      this.router.navigate(["/address"]);
    }, 500);
  }
  keyword = "city";
}
