import { Component, OnInit, ViewChild } from "@angular/core";
import { Address, FormData } from "../data/formData.model";
import { FormDataService } from "../data/formData.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { UserService } from "../user.service";
import { PlatformLocation } from "@angular/common";
import { NgxUiLoaderService } from "ngx-ui-loader";

import SignaturePad from "signature_pad";
import { environment } from "../../../src/environments/environment";
@Component({
  selector: "app-signature",
  templateUrl: "./signature.component.html",
  styleUrls: ["./signature.component.css"],
})
export class SignatureComponent implements OnInit {
  public signature: boolean = true;
  public _signature: any = null;
  sign_value: any;
  address: Address;
  sign_ar_v: any;
  public formdata: FormData;
  public lan: any;
  public service_url = environment.API_BASE_URL;
  public insignpage: boolean = false;
  response: any;
  signlater: any;
  signlatercheck: boolean = false;
  signlatercheck1: boolean = false;

  @ViewChild("sPad", { static: true }) signaturePadElement;
  signaturePad: any;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private formDataService: FormDataService,
    private userservice: UserService,
    public location: PlatformLocation,
    private ngxService: NgxUiLoaderService
  ) {
    console.log('sign');
    
    this.address = this.formDataService.getAddress();
    this.formdata = this.formDataService.getFormData();
    this.formDataService.setAddress(this.address);
    this.lan = this.formdata.language;
    translate.setDefaultLang(this.lan);

    // if (this.service_url == "http://localhost:4200") {
    //   this.service_url = "https://devforms.claimflights.com";
    // }
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(
      this.signaturePadElement.nativeElement
    );
  }

  undo() {
    const data = this.signaturePad.toData();
    this.sign_value = this.signaturePad.toDataURL();
    this.address.sign_valu = this.sign_value;
    this.sign_ar_v = this.signaturePad.toData();
    this.address.sign_ar = this.sign_ar_v;
    this.formDataService.setAddress(this.address);
  }

  clear() {
    this.signlater = false;
    this.signaturePad.clear();
    this.sign_value = "";
    this.address.sign_valu = this.sign_value;
    this.address.sign_ar = "";
    this.formDataService.setAddress(this.address);
    if (this.signlater == false) {
      let element = <HTMLInputElement>document.querySelector(".finishbutton");
      element.disabled = true;
    }
  }

  checkbox_val() {
    if (this.signlater) {
      this.signlatercheck = false;
      let element = <HTMLInputElement>document.querySelector(".finishbutton");
      element.disabled = false;
      this.signlater = true;
      this.signaturePad.clear();
      this.sign_value = "";
      this.address.sign_valu = this.sign_value;
      this.address.sign_ar = "";
      this.formDataService.setAddress(this.address);
    } else {
      let element = <HTMLInputElement>document.querySelector(".finishbutton");
      if (this.signlatercheck == true) {
        element.disabled = false;
      } else {
        this.signlatercheck = false;
        element.disabled = true;
      }
    }
  }
  goToNext(form: any) {
    this.sign_value = this.signaturePad.toDataURL();
    this.address.sign_valu = this.sign_value;

    this.sign_ar_v = this.signaturePad.toData();
    this.address.sign_ar = this.sign_ar_v;

    this.ngxService.start();
    this.insignpage = false;
    let secondstepval = "4page4step";
    this.address.progressvalue = secondstepval;
    this.address.sign_valu = this.sign_value;
    this.formDataService.setAddress(this.address);
    if (this.save(form)) {
      let z = this.service_url + "claim_record.php";
      this.userservice.For_Formdata(this.formdata, z).subscribe((response) => {
        this.response = response;
        if (this.response == null) {
          this.ngxService.stop();
        }
      });
      this.router.navigate(["/finish"]);
    }
  }

  ngOnInit() {
    var canvas: any = <HTMLCanvasElement>document.querySelector("canvas");
    canvas.addEventListener("mouseup", function () {
      this.signlatercheck = true;
      let element = <HTMLInputElement>document.querySelector(".finishbutton");
      element.disabled = false;
    });
    canvas.addEventListener("touchstart", function () {
      this.signlatercheck = true;
      let element = <HTMLInputElement>document.querySelector(".finishbutton");
      element.disabled = false;
    });
    this.loadScript(
      "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.js"
    );
    let element = <HTMLInputElement>document.querySelector(".finishbutton");
    element.disabled = true;
  }
  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement("script");
    script.innerHTML = "";
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  save(form: any): boolean {
    if (!form.valid) {
      return false;
    }

    this.formDataService.setAddress(this.address);
    return true;
  }
}
