import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FormDataService } from "../data/formData.service";
import { FormData } from "../data/formData.model";
import { environment } from "../../../src/environments/environment";

@Component({
  selector: "app-reject-claim",
  templateUrl: "./reject-claim.component.html",
  styleUrls: ["./reject-claim.component.css"],
})
export class RejectClaimComponent implements OnInit {
  public lan: any;
  public formData: FormData;
  service_url: any = environment.API_BASE_URL;

  constructor(
    public translate: TranslateService,
    private formDataService: FormDataService
  ) {
    this.formData = this.formDataService.getFormData();
    let form_url = window.location.hostname;
    console.log(form_url);
    
    let final_lan;
    if (this.service_url == "http://localhost:4200") {
      // this.service_url = "https://devforms.claimflights.com";
      final_lan = "com";
    } else {
      let form_url_split = form_url.split(".claimflights.");
      if (
        form_url_split[0] == "forms" ||
        form_url_split[0] == "devforms" ||
        form_url_split[0] == "testing" ||
        form_url_split[0] == "liveforms"
      ) {
        final_lan = form_url_split[1];
      } else if (form_url_split[0] == "bgforms") {
        final_lan = "BG";
      } else if (form_url_split[0] == "esforms") {
        final_lan = "es";
      } else {
        final_lan = "com";
      }
    }
    translate.setDefaultLang(final_lan);
  }

  ngOnInit() {
    $(".nav").css({ display: "none" });
    console.log(this.translate.getDefaultLang());
    
  }

  clickFN(){
    if (window.location.origin == "https://forms.claimflights.it") {
      window.open("https://claimflights.it/diritti-del-passeggero/regolamento-ue-261-04/");

    } else if (window.location.origin == "https://forms.claimflights.ro") {
      window.open("https://claimflights.ro/drepturile-pasagerilor/regulamentul-ce-261-2004/");

    } else if (window.location.origin == "https://forms.claimflights.pl") {
      window.open("https://claimflights.pl/prawa-pasazera/ue-261/");

    } else if (window.location.origin == "https://forms.claimflights.com") {
      window.open("https://claimflights.com/eu-regulation/");

    } else if (window.location.origin == "https://forms.claimflights.de") {
      window.open("https://claimflights.de/fluggastrechte/eu-verordnung/");

    } else if (window.location.origin == "https://forms.claimflights.at") {
      window.open("https://claimflights.at/fluggastrechte/eu-verordnung/");

    } else if (window.location.origin == "https://forms.claimflights.co.uk") {
      window.open("https://claimflights.co.uk/eu261/regulation/");

    } else if (window.location.origin == "https://forms.claimflights.fr") {
      window.open("https://claimflights.fr/droit-des-passagers-aeriens/reglement-ce-261-2004/");

    } else if (
      window.location.origin == "https://forms.claimflights.es" ||
      window.location.origin == "https://esforms.claimflights.com"
    ) {
      window.open("https://claimflights.com/eu-regulation/");

    } else if (window.location.origin == "https://forms.claimflights.ru") {
      window.open("https://claimflights.com/eu-regulation/");

    }
    else if (window.location.origin == "https://testing.claimflights.com/") {
      window.open("https://claimflights.com/eu-regulation/");

    }
    else if (window.location.origin == "https://liveforms.claimflights.de/") {
      window.open("https://claimflights.de/fluggastrechte/eu-verordnung/");

    }
    else if (window.location.origin == "https://testing.claimflights.pl/") {
      window.open("https://claimflights.pl/prawa-pasazera/ue-261/");

    }
    else if (window.location.origin == "https://testing.claimflights.it/") {
      window.open("https://claimflights.it/diritti-del-passeggero/regolamento-ue-261-04/");

    }
    else if (window.location.hostname == "localhost") {
      window.open("https://claimflights.com/eu-regulation/");

    }
  }
}
