import { Injectable } from '@angular/core';

import { FormData, Personal, Address, Work, alternetiv, otherpassenger, connectingflights } from './formData.model';
import { WorkflowService } from '../workflow/workflow.service';
import { STEPS } from '../workflow/workflow.model';

@Injectable()
export class FormDataService {

    private formData: FormData = new FormData();
    private isPersonalFormValid: boolean = false;
    private isWorkFormValid: boolean = false;
    private isAddressFormValid: boolean = false;

    constructor(private workflowService: WorkflowService) {
    }

    getPersonal(): Personal {
        var personal: Personal = {
            manual_entry_without_variflight_from: this.formData.manual_entry_without_variflight_from,
            manual_entry_without_variflight_airline: this.formData.manual_entry_without_variflight_airline,
            test: this.formData.test,
            progressvalue: this.formData.progressvalue,
            departure_form: this.formData.departure_form,
            scheduled_on: this.formData.scheduled_on,
            scheduled_time_on: this.formData.scheduled_time_on,
            arrival_form: this.formData.arrival_form,
            arrival_on: this.formData.arrival_on,
            arrival_time_on: this.formData.arrival_time_on,
            varidetails: this.formData.varidetails,
            language: this.formData.language,
            flt_num: this.formData.flt_num,
            flt_dt: this.formData.flt_dt,
            Actual_Arrival_Options: this.formData.Actual_Arrival_Options,
            avi: this.formData.avi,
            flightDetails: this.formData.flightDetails,
            field_manual_departure_form: this.formData.field_manual_departure_form,
            field_manual_scheduled_on: this.formData.field_manual_scheduled_on,
            field_manual_scheduled_time_on: this.formData.field_manual_scheduled_time_on,
            field_manual_arrival_form: this.formData.field_manual_arrival_form,
            field_manual_scheduled_arrival_on: this.formData.field_manual_scheduled_arrival_on,
            field_manual_scheduled_arrival_time_on: this.formData.field_manual_scheduled_arrival_time_on,
            manual_entry_without_variflight_fromdate: this.formData.manual_entry_without_variflight_fromdate,
            manual_entry_without_variflight_to: this.formData.manual_entry_without_variflight_to,
            manual_entry_without_variflight_todate: this.formData.manual_entry_without_variflight_todate,
            manual_entry_without_variflight_fromtime: this.formData.manual_entry_without_variflight_fromtime,
            manual_entry_without_variflight_totime: this.formData.manual_entry_without_variflight_totime,
            rejected_airline: this.formData.rejected_airline

        };
        return personal;
    }

    setPersonal(data: Personal) {
        this.formData.test = data.test;
        this.formData.manual_entry_without_variflight_from = data.manual_entry_without_variflight_from;
        this.formData.manual_entry_without_variflight_airline = data.manual_entry_without_variflight_airline;
        this.isPersonalFormValid = true;
        this.formData.progressvalue = data.progressvalue;
        this.formData.departure_form = data.departure_form;
        this.formData.scheduled_on = data.scheduled_on;
        this.formData.scheduled_time_on = data.scheduled_time_on;
        this.formData.arrival_form = data.arrival_form;
        this.formData.arrival_on = data.arrival_on;
        this.formData.arrival_time_on = data.arrival_time_on;
        this.formData.varidetails = data.varidetails;
        this.formData.language = data.language;
        this.formData.flt_num = data.flt_num;
        this.formData.flt_dt = data.flt_dt;
        this.formData.Actual_Arrival_Options = data.Actual_Arrival_Options;
        this.formData.avi = data.avi;
        this.formData.flightDetails = data.flightDetails;
        this.formData.field_manual_departure_form = data.field_manual_departure_form;
        this.formData.field_manual_scheduled_on = data.field_manual_scheduled_on;
        this.formData.field_manual_scheduled_time_on = data.field_manual_scheduled_time_on;
        this.formData.field_manual_arrival_form = data.field_manual_arrival_form;
        this.formData.field_manual_scheduled_arrival_on = data.field_manual_scheduled_arrival_on;
        this.formData.field_manual_scheduled_arrival_time_on = data.field_manual_scheduled_arrival_time_on;
        this.formData.manual_entry_without_variflight_fromdate = data.manual_entry_without_variflight_fromdate;
        this.formData.manual_entry_without_variflight_to = data.manual_entry_without_variflight_to;
        this.formData.manual_entry_without_variflight_todate = data.manual_entry_without_variflight_todate;
        this.formData.manual_entry_without_variflight_fromtime = data.manual_entry_without_variflight_fromtime;
        this.formData.manual_entry_without_variflight_totime = data.manual_entry_without_variflight_totime;
        this.formData.rejected_airline = data.rejected_airline;
        this.workflowService.validateStep(STEPS.personal);
    }

    getWork(): Work {
        var workType: Work = {
            progressvalue: this.formData.progressvalue,
            actual_arrival_time: this.formData.actual_arrival_time,
            actual_arrival_date: this.formData.actual_arrival_date,
            inform: this.formData.inform,
            Who_informed: this.formData.Who_informed,
            delay_reason: this.formData.delay_reason,
            delay_reason_details: this.formData.delay_reason_details,
            Denayed_Boarding: this.formData.Denayed_Boarding,
            delay_reason_check_in: this.formData.delay_reason_check_in,
            delay_reason_weather_option: this.formData.delay_reason_weather_option,
            delay_reason_other_option: this.formData.delay_reason_other_option,
            delay_reason_tech_option: this.formData.delay_reason_tech_option,
            backprocessvalue: this.formData.backprocessvalue
        };
        return workType;
    }

    setWork(data: Work) {
        this.isWorkFormValid = true;
        this.formData.progressvalue = data.progressvalue;
        this.formData.actual_arrival_time = data.actual_arrival_time;
        this.formData.actual_arrival_date = data.actual_arrival_date;
        this.formData.inform = data.inform;
        this.formData.Who_informed = data.Who_informed;
        this.formData.delay_reason_details = data.delay_reason_details;
        this.formData.Denayed_Boarding = data.Denayed_Boarding;
        this.formData.delay_reason_check_in = data.delay_reason_check_in;
        this.formData.delay_reason_weather_option = data.delay_reason_weather_option;
        this.formData.delay_reason_other_option = data.delay_reason_other_option;
        this.formData.delay_reason_tech_option = data.delay_reason_tech_option;
        this.formData.delay_reason = data.delay_reason
        this.formData.backprocessvalue = data.backprocessvalue;
        this.workflowService.validateStep(STEPS.work);

    }

    getAddress(): Address {
        var address: Address = {
            CaseNumber: this.formData.CaseNumber,
            backclick: this.formData.backclick,
            progressvalue: this.formData.progressvalue,
            distance: this.formData.distance,
            compensation_details_with_other_passenger: this.formData.compensation_details_with_other_passenger,
            compensation_details: this.formData.compensation_details,
            street: this.formData.street,
            city: this.formData.city,
            state: this.formData.state,
            zip: this.formData.zip,
            first_name: this.formData.first_name,
            last_name: this.formData.last_name,
            useremail: this.formData.useremail,
            country: this.formData.country,
            phone: this.formData.phone,
            user_title: this.formData.user_title,
            sign_valu: this.formData.sign_valu,
            sign_ar: this.formData.sign_ar,
            booking: this.formData.booking,
            file_upload: this.formData.file_upload,
            file_upload_data: this.formData.file_upload_data,
            terms_checkbox: this.formData.terms_checkbox,
            vat_percentage: this.formData.vat_percentage,
            usercurrentdatetime: this.formData.usercurrentdatetime,
            backprocessvalue: this.formData.backprocessvalue
        };
        return address;
    }

    setAddress(data: Address) {
        this.isAddressFormValid = true;
        this.formData.CaseNumber = data.CaseNumber;
        this.formData.backclick = data.backclick;
        this.formData.progressvalue = data.progressvalue;
        this.formData.distance = data.distance;
        this.formData.compensation_details = data.compensation_details;
        this.formData.compensation_details_with_other_passenger = data.compensation_details_with_other_passenger;
        this.formData.street = data.street;
        this.formData.city = data.city;
        this.formData.state = data.state;
        this.formData.zip = data.zip;
        this.formData.first_name = data.first_name;
        this.formData.last_name = data.last_name;
        this.formData.useremail = data.useremail;
        this.formData.phone = data.phone;
        this.formData.country = data.country;
        this.formData.user_title = data.user_title;
        this.formData.sign_valu = data.sign_valu;
        this.formData.sign_ar = data.sign_ar;
        this.formData.booking = data.booking;
        this.formData.file_upload = data.file_upload;
        this.formData.file_upload_data = data.file_upload_data;
        this.formData.terms_checkbox = data.terms_checkbox;
        this.formData.vat_percentage = data.vat_percentage;
        this.formData.usercurrentdatetime = data.usercurrentdatetime;
        this.formData.backprocessvalue = data.backprocessvalue;
        this.workflowService.validateStep(STEPS.address);

    }
    getalternetiv(): alternetiv {
        var alternetive: alternetiv = {
            progressvalue: this.formData.progressvalue,
            react: this.formData.react,
            Myself_or_None: this.formData.Myself_or_None,
        };
        return alternetive;
    }

    setalternetiv(data: alternetiv) {
        this.formData.progressvalue = data.progressvalue;
        this.formData.react = data.react;
        this.formData.Myself_or_None = data.Myself_or_None;
    }
    getotherpassenger(): otherpassenger {
        var Otherpassenger: otherpassenger = {
            otherpass: this.formData.otherpass,
        };
        return Otherpassenger;
    }

    setotherpassenger(data: otherpassenger) {
        this.formData.otherpass = data.otherpass;

    }
    getconnectingflights(): connectingflights {
        var CONNECTINGFLIGHTS: connectingflights = {
            connectingflightsDetails: this.formData.connectingflightsDetails,
        }
        return CONNECTINGFLIGHTS;
    }
    setconnectingflights(data: connectingflights) {
        this.formData.connectingflightsDetails = data.connectingflightsDetails;
    }

    getFormData(): FormData {
        return this.formData;
    }

    resetFormData(): FormData {
        this.workflowService.resetSteps();
        this.formData.clear();
        this.isPersonalFormValid = this.isWorkFormValid = this.isAddressFormValid = false;
        return this.formData;
    }

    isFormValid() {
        return this.isPersonalFormValid &&
            this.isWorkFormValid &&
            this.isAddressFormValid;
    }
}
