import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from "@angular/forms";
import { connectingflights, FormData } from "../data/formData.model";
import { FormDataService } from "../data/formData.service";
import { HttpClient } from "@angular/common/http";
import { UserService } from "../user.service";
import { DatePipe } from "@angular/common";
import {
  NativeDateAdapter,
  DateAdapter,
  MAT_DATE_FORMATS
} from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "../date.adapter";

import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, of } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { isArray } from "util";
import { timeStamp } from "console";
import { environment } from "../../../src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class Service {
  public service_url = environment.API_BASE_URL;

  constructor(
    private httpClient: HttpClient,
    private formDataService: FormDataService
  ) {
    this.formdata = this.formDataService.getFormData();

    // if (this.service_url == "http://localhost:4200") {
    //   this.service_url = "https://devforms.claimflights.com";
    // }
  }

  jokes = [];
  formdata: FormData;

  getData(a) {
    if (a == "") {
      return (this.jokes = undefined);
    } else {
      return this.httpClient
        .get<any>(
          this.service_url +
          "airport_autocomplete.php?api_key=cf-2019-1228-9c7b&keyword=" +
          a
        )
        .pipe(
          map((data) => {
            if(data == null){
              
            }else{
              if (data[0]) {
                if (data[0].iata_code == "Not Found") {
                  setTimeout(() => {
                    let element = document.getElementById("mat-autocomplete-1");
                    if (element) {
                      element.classList.remove("mat-autocomplete-visible");
                      this.formdata.autodatacheck_status = true;
                    }
                  }, 10);
                } else {
                  this.formdata.autodatacheck_status = false;
                }
              }
            }
            this.jokes = data;
            return this.jokes;
          })
        );
    }
  }
}

@Component({
  selector: "app-connecting-flights",
  templateUrl: "./connecting-flights.component.html",
  styleUrls: ["./connecting-flights.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
@Component({
  selector: "app-connecting-flights",
  templateUrl: "./connecting-flights.component.html",
  styleUrls: ["./connecting-flights.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class ConnectingFlightsComponent implements OnInit {
  startDate = new Date();

  CONNECTINGFLIGHTS: connectingflights;
  formdata: FormData;
  response: any;
  response2: any;
  form: any;
  public connectingflightsDetails: any;
  public myForm: UntypedFormGroup;
  public addButtonVisibility: boolean = false;
  public flight_input: boolean = true;
  public lan: any;
  public modefy_date_for: any;
  public modefy_date_for_arr: any;
  jokes;
  jokes1;
  currentJoke = "";
  currentJoke1 = "";
  connecting_flight_manual_date: any;
  public a: any;
  withdata: boolean = false;
  addButtonManual: boolean = false;
  manualentryToggle: boolean = false;
  public input_alert: any;
  public input_alert2: any;
  submitManual: boolean;
  withoutdata: boolean = false;
  toggle: boolean = false;
  public service_url = environment.API_BASE_URL;

  constructor(
    public translate: TranslateService,
    private _fb: UntypedFormBuilder,
    private formDataService: FormDataService,
    private svc: UserService,
    private http: HttpClient,
    public datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private service: Service
  ) {
    this.CONNECTINGFLIGHTS = this.formDataService.getconnectingflights();
    this.formdata = this.formDataService.getFormData();
    this.formDataService.setconnectingflights(this.CONNECTINGFLIGHTS);
    let langu = this.formdata.language;
    this.lan = "en";
    translate.setDefaultLang(langu);
    // if (this.service_url == "http://localhost:4200") {
    //   this.service_url = "https://devforms.claimflights.com";
    // }
  }

  ngOnInit() {
    this.CONNECTINGFLIGHTS = this.formDataService.getconnectingflights();
    this.formdata = this.formDataService.getFormData();
    this.myForm = this._fb.group({
      languages: this._fb.array([this.initpass()]),
    });
  }

  doFilter(item) {
    this.a = item;
    this.jokes = this.service
      .getData(this.a)
      .pipe(map((jokes) => this.filter(jokes)));
  }
  doFilter1(item) {
    this.a = item;
    this.jokes1 = this.service
      .getData(this.a)
      .pipe(map((jokes1) => this.filter(jokes1)));
  }

  filter(values) {
    return values.filter(
      (joke) =>
        joke.name.toLowerCase().includes(this.a) ||
        joke.iata_code.toLowerCase().includes(this.a) ||
        joke.city.toLowerCase().includes(this.a) ||
        joke.city_code.toLowerCase().includes(this.a)
    );
  }
  displayFn(jokes): string | undefined {
    if (jokes) {
      if (jokes.iata_code == "Not Found") {
        return "Not Found";
      } else {
        return jokes ? jokes.name + " (" + jokes.iata_code + ") " : undefined;
      }
    }
  }
  displayFn1(jokes1): string | undefined {
    if (jokes1) {
      if (jokes1.iata_code == "Not Found") {
        return "Not Found";
      } else {
        return jokes1
          ? jokes1.name + " (" + jokes1.iata_code + ") "
          : undefined;
      }
    }
  }

  initpass() {
    return this._fb.group({
      connecting_flight_number: [""],
      connecting_flight_date: [""],
      currentJoke1: [""],
      currentJoke: [""],
      from:[""],
      to:[""],
      connecting_flight_manual_date: [""],
      connecting_flight_all_Details: [""],
    });
  }
  addLanguage() {
    const control = <UntypedFormArray>this.myForm.controls["languages"];
    control.push(this.initpass());
    this.addButtonManual = false;
  }

  addLanguage_manual() {
    const control = <UntypedFormArray>this.myForm.controls["languages"];
    control.push(this.initpass());
    this.response2 = false;
    this.manualentryToggle = false;
    this.submitManual = true;
  }
  find() {
    this.spinner.show();
    this.addButtonManual = false;
    const control = <UntypedFormArray>this.myForm.controls["languages"];
    let index_lenth = control.length - 1;
    let y = this.myForm.value.languages[index_lenth].currentJoke1.iata_code;
    let x = this.myForm.value.languages[index_lenth].currentJoke.iata_code;

    if (x == undefined && y == undefined) {
      this.input_alert = true;
      this.input_alert2 = true;
      this.spinner.hide();
      return false;
    }

    if (x == "Not Found" && y == "Not Found") {
      this.input_alert = true;
      this.input_alert2 = true;
      this.spinner.hide();
      return false;
    }

    if (y == undefined || y == "Not Found") {
      this.input_alert = true;
      this.input_alert2 = false;
      this.spinner.hide();
      return false;
    }
    if (x == undefined || x == "Not Found") {
      this.input_alert2 = true;
      this.input_alert = false;
      this.spinner.hide();
      return false;
    }

    let v = this.myForm.value.languages[index_lenth]
      .connecting_flight_manual_date;
    let flightDate = this.datePipe.transform(v, "dd.MM.yyyy");
    let z =
      this.service_url +
      "api/v1/variflight/?api_key=cf-2019-1228-9c7b&dep=" +
      y +
      "&arr=" +
      x +
      "&flightDate=" +
      flightDate;
    this.svc.For_connectingflights_manual(z).subscribe((responseManual) => {
      if (Array.isArray(this.response) == true) {
        this.response2 = responseManual;
      } else {
        this.response2 = Array.of(responseManual);
      }

      if (
        this.response2.scheduled_departure != null ||
        this.response2[0].FlightNo != null
      ) {
        this.withdata = true;
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.withdata = false;
        this.withoutdata = true;
      }
    });

    if (this.response != null) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }

  to_variflight_manual() {
    this.withoutdata = false;
    this.manualentryToggle = true;
    this.toggle = true;
    this.response2 = false;
  }

  removeLanguage(i: number) {
    const control = <UntypedFormArray>this.myForm.controls["languages"];
    control.removeAt(i);
    this.CONNECTINGFLIGHTS.connectingflightsDetails = this.myForm.value.languages;
    this.formDataService.setconnectingflights(this.CONNECTINGFLIGHTS);
  }

  save(i: number) {
    this.spinner.show();
    this.addButtonVisibility = true;
    const control = <UntypedFormArray>this.myForm.controls["languages"];
    let index_lenth = control.length - 1;
    this.formdata.avi = false;

    let x = this.myForm.value.languages[index_lenth].connecting_flight_date;
    let y = this.myForm.value.languages[
      index_lenth
    ].connecting_flight_number.replace(/\s/g, "");
    let k = this.datePipe.transform(x, "dd.MM.yyyy");
    let z =
      this.service_url +
      "api/v1/variflight/?api_key=cf-2019-1228-9c7b&flightNo=" +
      y +
      "&flightDate=" +
      k;
    this.svc
      .For_connectingflights(this.connectingflightsDetails, z)
      .subscribe((response) => {
        this.response = response;
        if (
          this.response == null ||
          this.response[1] == "Ao data" ||
          this.response[1] == "Arong flight number format" ||
          this.response[1] === "Vo data" ||
          this.response[1] === "Vrong departure airport format" ||
          this.response[1] == "Vrong flight number format"
        ) {
          this.spinner.hide();
          this.manualentryToggle = true;
         
          this.CONNECTINGFLIGHTS.connectingflightsDetails[0].connecting_flight_date = this.datePipe.transform(this.CONNECTINGFLIGHTS.connectingflightsDetails[0].connecting_flight_date,"dd.MM.yyyy");
          this.formDataService.setconnectingflights(this.CONNECTINGFLIGHTS);
        } else {
          this.spinner.hide();
          if (isArray(this.response)) {
            this.manualentryToggle = true;
          } else {
            this.spinner.hide();
      
            this.myForm.value.languages[index_lenth].connecting_flight_date = k;
            this.myForm.value.languages[
              index_lenth
            ].connecting_flight_all_Details = this.response;
          }
        }
      });
    
    this.CONNECTINGFLIGHTS.connectingflightsDetails = this.myForm.value.languages;  
    this.formDataService.setconnectingflights(this.CONNECTINGFLIGHTS);
  }
  select_flight(d) {
    this.addButtonManual = true;
    const control = <UntypedFormArray>this.myForm.controls["languages"];
    let index_lenth = control.length - 1;
    this.response2 = d;

    this.myForm.value.languages[index_lenth].connecting_flight_all_Details = this.response2;
    this.myForm.value.languages[index_lenth].connecting_flight_number = this.myForm.value.languages[index_lenth].connecting_flight_all_Details.FlightNo;   
    
    this.myForm.value.languages[index_lenth].connecting_flight_date = this.datePipe.transform(this.myForm.value.languages[index_lenth].connecting_flight_manual_date,"dd.MM.yyyy");

    this.myForm.value.languages[index_lenth].connecting_flight_manual_date = this.datePipe.transform(this.myForm.value.languages[index_lenth].connecting_flight_manual_date,"dd.MM.yyyy");
    
    this.myForm.value.languages[index_lenth].from = this.myForm.value.languages[index_lenth].currentJoke1;

    this.myForm.value.languages[index_lenth].to = this.myForm.value.languages[index_lenth].currentJoke;

    this.CONNECTINGFLIGHTS.connectingflightsDetails = this.myForm.value.languages;
    this.formDataService.setconnectingflights(this.CONNECTINGFLIGHTS);
  }
}
