import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import * as $ from "jquery";
import { FormDataService } from "../data/formData.service";
import { FormData, Work } from "../data/formData.model";
import {
  NativeDateAdapter,
  DateAdapter,
  MAT_DATE_FORMATS
} from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "../date.adapter";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

import { NgxUiLoaderService } from "ngx-ui-loader";
import { DatePipe } from "@angular/common";
import * as moment from "moment";

@Component({
  selector: "mt-wizard-work",
  templateUrl: "./work.component.html",
  styleUrls: ["./work.component.css"],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class WorkComponent implements OnInit {
  title = "What do you do?";

  startDate = new Date();
  formdata: FormData;
  btndisable: boolean;

  @Input() formData: FormData;
  workType: Work;
  //work: Work;
  form: any;
  selectedDay: string = "";
  containers = [];
  public show: boolean = false;
  public show1: boolean = false;
  public delete: boolean = true;
  public showalt: boolean = false;
  public plannededitdiv: boolean = false;
  public actualeditdiv: boolean = false;

  public plannedusrdate: string;
  public plannedusrtime: string;

  public inform_airlines: boolean = false;
  public Delay_hours_minutes: any;
  public flight_status: boolean = true;
  public Ishide: boolean = false;
  public b2_2ndslide: boolean = true;
  public Actual_arrival_detail_for_2b: boolean = true;
  public describe_your_problem: boolean = true;
  public flight_cancellation: boolean = true;
  public informed_by: boolean = true;
  public Reason_For_Denayed: boolean = true;
  public flight_cancellation_FOR_2E: boolean = true;
  public flight_delay_reason: boolean = true;
  public step_2_for_rerouted: boolean = true;
  public step_3_check_in: boolean = true;
  public step_3_tech_prob: boolean = true;
  public step_3_weather: boolean = true;
  public step_3_other: boolean = true;
  public describe_about_problem: boolean = true;
  public flight_cancel_reason: boolean = true;
  public step_3_check_in_under_cancel: boolean = true;
  public step_3_tech_prob_under_cancel: boolean = true;
  public step_3_weather_under_cancel: boolean = true;
  public step_3_other_under_cancel: boolean = true;
  public describe_about_problem_under_cancel = true;
  public alter_option_under_cancel = true;
  public alter_section_under_denied_bording: boolean = true;
  public whome_inform_under_rerouted: boolean = true;
  public step_3_check_in_under_rerouted: boolean = true;
  public step_3_tech_prob_under_rerouted: boolean = true;
  public step_3_weather_under_rerouted: boolean = true;
  public step_3_other_under_rerouted: boolean = true;
  public describe_about_problem_under_rerouted: boolean = true;
  public alter_option_under_rerouted: boolean = true;
  public step_3_tech_prob_under_return_departure: boolean = true;
  public step_3_weather_under_return_departure: boolean = true;
  public step_3_other_under_return_departure: boolean = true;
  public describe_about_problem_under_returned_departure: boolean = true;
  public alter_option_under_returned_departure: boolean = true;
  public lane: any;
  public timediffInMs: any;
  public backvalue: any;
  public inportantname: any;
  public hr: any;
  public min: any;
  public t: any;
  public u: any;
  public date_placeholder: any;
  public flt_com: string;
  public pre_DateFormat: any;
  j = 0;
  value: any;
  public ages = [];
  flightNumberCheck = [];
  flight_Number: any;
  date11new: Date;

  constructor(
    private ngxService: NgxUiLoaderService,
    public translate: TranslateService,
    public datepipe: DatePipe,
    private router: Router,
    private formDataService: FormDataService
  ) {
    this.workType = this.formDataService.getWork();
    this.formData = this.formDataService.getFormData();
    console.log(this.formData);
    this.lane = this.formData.language;
    translate.setDefaultLang(this.lane);
  }
  public dd: any;
  public mm: any;
  public yyyy: any;
  public today: any;
  ngOnInit() {
    this.formData = this.formDataService.getFormData();
    this.workType = this.formDataService.getWork();
    this.flt_com = this.formData.flightDetails.FlightCompany;
    this.flight_Number = this.formData.flightDetails.FlightNo;

    function datepipechange(date) {
      var changedate = date.split(" ");
      var changeddate = changedate[0].split(".");
      var timecheck = changedate[1].split(":");
      var timecheckhour = timecheck[0];
      var timecheckmin = timecheck[1];
      var timechecksec = timecheck[2];

      if (timecheckhour.length == 1) {
        timecheckhour = "0" + timecheckhour;
      } else {
        timecheckhour = timecheckhour;
      }
      if (timecheckmin.length == 1) {
        timecheckmin = "0" + timecheckmin;
      } else {
        timecheckmin = timecheckmin;
      }
      if (timechecksec.length == 1) {
        timechecksec = "0" + timechecksec;
      } else {
        timechecksec = timechecksec;
      }
      changeddate =
        changeddate[2] +
        "-" +
        changeddate[1] +
        "-" +
        changeddate[0] +
        "T" +
        timecheckhour +
        ":" +
        timecheckmin +
        ":" +
        timechecksec;

      return changeddate;
    }

    if (
      this.formData.flightDetails.FlightDepcode == null ||
      this.formData.flightDetails.FlightDep == null ||
      this.formData.flightDetails.scheduled_departure == "N/A" ||
      this.formData.flightDetails.FlightArrcode == null ||
      this.formData.flightDetails.FlightArr == null ||
      this.formData.flightDetails.scheduled_arrival == "N/A"
    ) {
      this.router.navigate(["/enter_flight_details"]);
    }
    this.inportantname = this.formData.Actual_Arrival_Options;
    this.backvalue = this.formData.backprocessvalue;

    if (this.inportantname == "") {
      this.ngxService.start();
      this.router.navigate(["/enter_flight_details"]);
    } else if (
      this.inportantname == "My_flight_was_cancelled" &&
      this.backvalue == "4"
    ) {
      this.flight_cancellation = true;
      this.alter_option_under_cancel = false;
    } else if (
      this.inportantname == "My_flight_was_delayed" &&
      this.backvalue == "4"
    ) {
      this.Actual_arrival_detail_for_2b = true;
      this.describe_about_problem = false;
    } else if (
      this.inportantname == "I_was_denied_boarding" &&
      this.backvalue == "4"
    ) {
      this.alter_section_under_denied_bording = false;
      this.Reason_For_Denayed = true;
    } else if (
      this.inportantname == "My_flight_was_rerouted" &&
      this.backvalue == "4"
    ) {
      this.alter_option_under_rerouted = false;
      this.describe_about_problem_under_rerouted = true;
    } else if (
      this.inportantname == "Returned_to_the_departure_airport" &&
      this.backvalue == "4"
    ) {
      this.alter_option_under_returned_departure = false;
      this.describe_about_problem_under_returned_departure = true;
    } else {
    }

    let a = this.formData;
    if (
      a.Actual_Arrival_Options == "My_flight_was_delayed" &&
      this.backvalue != "4"
    ) {
      this.Actual_arrival_detail_for_2b = false;
      let actual_ari_date_time = this.formData.flightDetails.actual_arrival;
      if (actual_ari_date_time == "N/A") {
        actual_ari_date_time = this.formData.flightDetails.scheduled_arrival;
      }
      if (this.formData.connectingflightsDetails != null) {
        actual_ari_date_time = this.formData.connectingflightsDetails[
          this.formData.connectingflightsDetails.length - 1
        ].connecting_flight_all_Details.scheduled_arrival;
        if (actual_ari_date_time == "N/A") {
          actual_ari_date_time = this.formData.connectingflightsDetails[
            this.formData.connectingflightsDetails.length - 1
          ].connecting_flight_all_Details.scheduled_arrival;
        }
      } else {
        actual_ari_date_time = this.formData.flightDetails.actual_arrival;
        if (actual_ari_date_time == "N/A") {
          actual_ari_date_time = this.formData.flightDetails.scheduled_arrival;
        }
      }
      let date_time = actual_ari_date_time.split(" ");
      this.today = new Date(date_time[0]);
      // console.log(date_time[0]);
      // console.log(this.today);
      // console.log(this.datepipe.transform(this.today,'long'));
      
      this.dd = this.today.getDate();
      this.mm = this.today.getMonth() + 1;
      this.yyyy = this.today.getFullYear();
      if (this.dd < 10) {
        this.dd = "0" + this.dd;
      }

      if (this.mm < 10) {
        this.mm = "0" + this.mm;
      }
      this.today = this.mm + "." + this.dd + "." + this.yyyy;
      this.today = this.mm + "." + this.dd + "." + this.yyyy;
      this.today = this.dd + "." + this.mm + "." + this.yyyy;
      this.today = this.dd + "." + this.mm + "." + this.yyyy;

      // console.log("a",this.today);
      // console.log("b",date_time);
      
      let onlyDate = date_time[0].split(".");
      if (this.formData.manual_entry_without_variflight_from != null) {
        this.pre_DateFormat =
          onlyDate[0] + "." + onlyDate[1] + "." + onlyDate[2];
      } else {
        this.pre_DateFormat =
          onlyDate[0] + "." + onlyDate[1] + "." + onlyDate[2];
      }
      let DateFormat = this.pre_DateFormat;
      let timeOnly = date_time[1];
      this.date_placeholder = DateFormat;
      console.log(onlyDate);
      
      console.log(new Date(onlyDate[2] + "/" + onlyDate[1] + "/" + onlyDate[0]));
      
      this.workType.actual_arrival_date = new Date(onlyDate[2] + "/" + onlyDate[1] + "/" + onlyDate[0]);
      this.date11new = new Date(onlyDate[1] + "/" + onlyDate[0] + "/" + onlyDate[2]);
      
      console.log("actual_arrival_date",this.workType.actual_arrival_date);
      console.log("date112",this.date11new);
      


      //////////////////////////////////////////////////////////////
      
      
      // this.workType.actual_arrival_date = new Date(this.today)
      // this.datepipe.transform(
      //   this.workType.actual_arrival_date,
      //   "dd.MM.yyyy"
      // );
      if (this.formData.Actual_Arrival_Options == "My_flight_was_delayed") {
        if (this.formData.connectingflightsDetails != null) {
          this.t = this.formData.connectingflightsDetails[
            this.formData.connectingflightsDetails.length - 1
          ].connecting_flight_all_Details.scheduled_arrival;
        } else {
          this.t = this.formData.flightDetails.scheduled_arrival;
        }
      } else {
        this.t = this.formData.flightDetails.scheduled_arrival;
      }
      if (this.formData.connectingflightsDetails != null) {
        this.u = this.formData.connectingflightsDetails[
          this.formData.connectingflightsDetails.length - 1
        ].connecting_flight_all_Details.actual_arrival;
      } else {
        this.u = this.formData.flightDetails.actual_arrival;
      }
      if (this.u == "N/A") {
        this.u = this.formData.flightDetails.scheduled_arrival;
      }
      const flightdate1 = this.u;
      const flightdate2 = this.t;
      const date_time_afterchange = datepipechange(this.u);
      const u_time_afterchange = datepipechange(this.t);

      let diffInMs: number =
        Date.parse(date_time_afterchange) - Date.parse(u_time_afterchange);
      if (isNaN(diffInMs)) {
        diffInMs = Date.parse(this.u) - Date.parse(this.t);
      }
      let diffInHours: number = diffInMs / 1000 / 60 / 60;
      let DiffInHours1: any = diffInHours;
      this.timediffInMs = DiffInHours1;
      let time_in_hour: string = this.timediffInMs.toString().split(".");
      let time_in_hr1 = "." + time_in_hour[1];
      let time_in_hr2: any = time_in_hr1;
      let DelayyTime = time_in_hr2 * 60;

      if (this.timediffInMs == 0) {
        DelayyTime = 0;
      }
      let Detay_hours_minutes =
        time_in_hour[0] + " " + "hours" + " " + DelayyTime + " " + "minutes";
      this.Delay_hours_minutes = Detay_hours_minutes;
      this.timediffInMs = DiffInHours1;
      this.min = DelayyTime;
      this.hr = time_in_hour[0];

      if (this.hr < 0) {
      }

      let timein_arr: string = timeOnly.toString().split(":");
      this.workType.actual_arrival_time = {
        hour: +timein_arr[0],
        minute: +timein_arr[1],
        second: 0,
      };
      this.formDataService.setWork(this.workType);
    } else if (
      a.Actual_Arrival_Options == "My_flight_was_cancelled" &&
      this.backvalue != "4"
    ) {
      this.flight_cancellation = false;
    } else if (
      a.Actual_Arrival_Options == "I_was_denied_boarding" &&
      this.backvalue != "4"
    ) {
      this.Reason_For_Denayed = false;
    } else if (
      a.Actual_Arrival_Options == "My_flight_was_rerouted" &&
      this.backvalue != "4"
    ) {
      this.flight_cancellation_FOR_2E = false;
    } else if (
      a.Actual_Arrival_Options == "Returned_to_the_departure_airport" &&
      this.backvalue != "4"
    ) {
      this.flight_delay_reason = false;
    }

    if (
      this.formData.flightDetails.rejected_airline == 1 ||
      this.formData.rejected_airline == 1
    ) {
      this.router.navigate(["/reject-claim"]);
    }else{
      let eu_o = this.formData.flightDetails.arr_in_eu;
      let eu_d = this.formData.flightDetails.dep_in_eu;
  
      ////////////////with  EU AIRLINE
      if(this.formData.flightDetails.airline_eu_status == 1 ){
      if(eu_o == 0 && eu_d == 0){
        this.router.navigate(['/reject-claim']);
      }
      }
      ////////////////with  NON-EU AIRLINE
      if(this.formData.flightDetails.airline_eu_status == 0 ){
        if(eu_o == 0 && eu_d == 0){
          this.router.navigate(['/reject-claim']);
  
        }
        else if(eu_o == 1 && eu_d == 0){
          this.router.navigate(['/reject-claim']);
        }
      }
    }
  }

  save(form: any): boolean {
    if (!form.valid) {
      return false;
    }
    this.formDataService.setWork(this.workType);
    return true;
  }

  goToPrevious(form: any) {
    if (this.save(form)) {
      this.router.navigate(["/enter_flight_details"]);
    }
  }

  goToNext(form: any) {
    if (this.save(form)) {
      this.router.navigate(["/address"]);
    }
  }

  ADD_ALTERNETIVE_TRANSPORT() {
    if (!this.show) {
      this.show = !this.show;
      this.show = true;
      this.show1 = true;
      this.showalt = true;
      this.delete = false;
    } else {
    }
  }

  DELETE_ALTERNETIVE_TRANSPORT() {
    if (this.show) {
      this.show = !this.show;
      this.show = false;
      this.show1 = false;
      this.delete = true;
      this.showalt = false;
    } else {
    }
  }

  plannededit() {
    if (!this.plannededitdiv) {
      this.plannededitdiv = !this.plannededitdiv;
      this.plannededitdiv = true;
    }
  }
  actualedit() {
    this.actualeditdiv = !this.actualeditdiv;
    this.actualeditdiv = true;
  }

  flight_cancel() {
    if (!this.inform_airlines) {
      this.inform_airlines = true;
    } else {
      this.inform_airlines = false;
    }
  }

  My_flight_was_cancelled() {
    if (this.backvalue != "4") {
      this.flight_status = true;
      this.flight_cancellation = false;
    } else {
    }
  }

  My_flight_was_delayed() {
    this.flight_status = true;
    this.Actual_arrival_detail_for_2b = false;
  }

  I_was_denied_boarding() {
    this.flight_status = true;
    this.describe_your_problem = false;
  }

  My_flight_was_rerouted() {
    this.flight_status = true;
    this.flight_cancellation_FOR_2E = false;
  }

  My_flight_returned_to_the_departure_airport() {
    this.flight_status = true;
    this.flight_delay_reason = false;
  }
  bktoMainReason() {
    // this.router.navigate(["/enter_flight_details"]);
  }
  reason2b() {
    if (this.timediffInMs < 3) {
      this.router.navigate(["/reject-claim"]);
    }

    let thiredstepval = "3page1step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.b2_2ndslide = false;
    this.Actual_arrival_detail_for_2b = true;
    this.formDataService.setWork(this.workType);
  }

  flight_st() {
    this.router.navigate(["/enter_flight_details"]);
  }

  before_7_days(val: any) {
    if (val == "More_then_14_before_departure_informed") {
      this.router.navigate(["/reject-claim"]);
    }

    let thiredstepval = "3page1step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.flight_cancellation = true;
    this.informed_by = false;
  }

  nxt_frm() {
    let thiredstepval = "3page2step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.describe_your_problem = true;
    this.alter_section_under_denied_bording = false;
  }
  sample(val: any) {
    if (val == "More_then_14_before_departure_informed") {
      this.router.navigate(["/reject-claim"]);
    }

    let thiredstepval = "3page1step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.whome_inform_under_rerouted = false;
    this.flight_cancellation_FOR_2E = true;
  }
  under_reroute_who_inform_bttn() {
    this.whome_inform_under_rerouted = true;
    this.step_2_for_rerouted = false;
  }

  chckn() {
    let thiredstepval = "3page2step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.b2_2ndslide = true;
    this.step_3_check_in = false;
  }
  tech_op() {
    let thiredstepval = "3page2step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.b2_2ndslide = true;
    this.step_3_tech_prob = false;
  }

  weather_op() {
    let thiredstepval = "3page2step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.b2_2ndslide = true;
    this.step_3_weather = false;
  }

  strik_nxt(val: any) {
    this.workType.delay_reason = val;
    this.formDataService.setWork(this.workType);
    this.router.navigate(["/reject-claim"]);
  }

  other_op() {
    let thiredstepval = "3page2step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.b2_2ndslide = true;
    this.step_3_other = false;
  }

  check_under_delay(val: any) {
    if (val == "Air_Traffic_Control_restriction") {
      this.workType.delay_reason_check_in = val;
      this.formDataService.setWork(this.workType);
      this.router.navigate(["/reject-claim"]);
    } else if (val == "Issues_with_Airport_infrastructure") {
      this.workType.delay_reason_check_in = val;
      this.formDataService.setWork(this.workType);
      this.router.navigate(["/reject-claim"]);
    } else if (val == "Airport_closure") {
      this.workType.delay_reason_check_in = val;
      this.formDataService.setWork(this.workType);
      this.router.navigate(["/reject-claim"]);
    } else if (val == "Wait_at_Immigration_Customs") {
      this.workType.delay_reason_check_in = val;
      this.formDataService.setWork(this.workType);
      this.router.navigate(["/reject-claim"]);
    }
    this.step_3_check_in = true;
    this.describe_about_problem = false;
  }

  prob_under_delay(val: any) {
    if (val == "Damage_during_flight") {
      this.router.navigate(["/reject-claim"]);
      this.workType.delay_reason_tech_option = val;
      this.formDataService.setWork(this.workType);
    }
    this.step_3_tech_prob = true;
    this.describe_about_problem = false;
  }

  weather_under_delay(val: any) {
    if (val != "Not_Sure") {
      this.router.navigate(["/reject-claim"]);
      this.workType.delay_reason_weather_option = val;
      this.formDataService.setWork(this.workType);
    }
    this.router.navigate(["/reject-claim"]);
    this.step_3_weather = true;
    this.describe_about_problem = false;
  }

  after_page(val: any) {
    if (val == "Air_Traffic_Control_restriction") {
      this.workType.delay_reason_other_option = val;
      this.formDataService.setWork(this.workType);
      this.router.navigate(["/reject-claim"]);
    } else if (val == "Medical_emergency") {
      this.workType.delay_reason_other_option = val;
      this.formDataService.setWork(this.workType);
      this.router.navigate(["/reject-claim"]);
    }

    this.step_3_other = true;
    this.describe_about_problem = false;
  }

  under_canel_who_inform_bttn() {
    this.flight_cancel_reason = false;
    this.informed_by = true;
  }

  other_op_under_cancel() {
    let thiredstepval = "3page2step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.step_3_other_under_cancel = false;
    this.flight_cancel_reason = true;
  }

  weather_op_under_cancel() {
    let thiredstepval = "3page2step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.step_3_weather_under_cancel = false;
    this.flight_cancel_reason = true;
  }

  Strike() { }

  tech_op_under_cancel() {
    let thiredstepval = "3page2step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.step_3_tech_prob_under_cancel = false;
    this.flight_cancel_reason = true;
  }

  chckn_under_cancel() {
    let thiredstepval = "3page2step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.step_3_check_in_under_cancel = false;
    this.flight_cancel_reason = true;
  }

  after_page_under_cancel(val: any) {
    if (val == "Air_Traffic_Control_restriction") {
      this.workType.delay_reason_other_option = val;
      this.formDataService.setWork(this.workType);
      this.router.navigate(["/reject-claim"]);
    } else if (val == "Medical_emergency") {
      this.workType.delay_reason_other_option = val;
      this.formDataService.setWork(this.workType);
      this.router.navigate(["/reject-claim"]);
    }
    this.describe_about_problem_under_cancel = false;
    this.step_3_other_under_cancel = true;
  }

  after_page_weather_under_cancel(val: any) {
    if (val != "Not_Sure") {
      this.workType.delay_reason_weather_option = val;
      this.formDataService.setWork(this.workType);
      this.router.navigate(["/reject-claim"]);
    }
    this.describe_about_problem_under_cancel = false;
    this.step_3_weather_under_cancel = true;
  }

  after_page_technical_under_cancel(val: any) {
    if (val == "Damage_during_flight") {
      this.router.navigate(["/reject-claim"]);
      this.workType.delay_reason_tech_option = val;
      this.formDataService.setWork(this.workType);
    }
    this.describe_about_problem_under_cancel = false;
    this.step_3_tech_prob_under_cancel = true;
  }

  after_page_check_under_cancel(val: any) {
    if (val == "Issues_with_Airport_infrastructure") {
      this.router.navigate(["/reject-claim"]);
      this.workType.delay_reason_check_in = val;
      this.formDataService.setWork(this.workType);
    } else if (val == "Airport_closure") {
      this.router.navigate(["/reject-claim"]);
      this.workType.delay_reason_check_in = val;
      this.formDataService.setWork(this.workType);
    } else if (val == "Wait_at_Immigration_Customs") {
      this.workType.delay_reason_check_in = val;
      this.formDataService.setWork(this.workType);
      this.router.navigate(["/reject-claim"]);
    }
    this.describe_about_problem_under_cancel = false;
    this.step_3_check_in_under_cancel = true;
  }

  back_b_under_cancel() {
    let backvalchange = "";
    this.workType.backprocessvalue = backvalchange;
    this.formDataService.setWork(this.workType);
    this.describe_about_problem_under_cancel = true;

    if (this.workType.delay_reason_other_option != "") {
      this.workType.delay_reason_other_option = "";
      this.step_3_other_under_cancel = false;
    } else if (this.workType.delay_reason_weather_option != "") {
      this.workType.delay_reason_weather_option = "";
      this.step_3_weather_under_cancel = false;
    } else if (this.workType.delay_reason_tech_option != "") {
      this.workType.delay_reason_tech_option = "";
      this.step_3_tech_prob_under_cancel = false;
    } else if (this.workType.delay_reason_check_in != "") {
      this.workType.delay_reason_check_in = "";
      this.describe_about_problem = true;
      this.step_3_check_in_under_cancel = false;
    } else {
    }
  }

  nxt_frm_under_cancel() {
    this.describe_about_problem_under_cancel = true;
    this.alter_option_under_cancel = false;
    this.formDataService.setWork(this.workType);
  }

  nxt_b(form: any) {
    let thiredstepval = "4page1step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.router.navigate(["/address"]);
  }

  Reason_For_Denayed_Boarding_under_denayed_bording(val: any) {
    this.Reason_For_Denayed = true;
    if (val == "Overbooked") {
      let thiredstepval = "3page1step";
      this.workType.progressvalue = thiredstepval;
      this.formDataService.setWork(this.workType);
      let values = "Overbooked";
      this.describe_your_problem = false;
      this.formData.Denayed_Boarding = values;
    }
    if (val == "Too_Late_at_gate_or_check_in_counter?") {
      this.router.navigate(["/reject-claim"]);
      let values = "Too_Late_at_gate_or_check_in_counter?";
      this.formData.Denayed_Boarding = values;
    }
    if (val == "Reason_of_Helth_Safety") {
      this.router.navigate(["/reject-claim"]);
      let values = "Reason_of_Helth_Safety";
      this.formData.Denayed_Boarding = values;
    }
  }

  chckn_under_rerouted() {
    let thiredstepval = "3page2step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.step_2_for_rerouted = true;
    this.step_3_check_in_under_rerouted = false;
  }

  tech_prob_under_rerouted() {
    let thiredstepval = "3page2step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.step_2_for_rerouted = true;
    this.step_3_tech_prob_under_rerouted = false;
  }

  weather_under_rerouted() {
    let thiredstepval = "3page2step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.step_2_for_rerouted = true;
    this.step_3_weather_under_rerouted = false;
  }

  other_under_rerouted() {
    let thiredstepval = "3page2step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.step_2_for_rerouted = true;
    this.step_3_other_under_rerouted = false;
  }

  check_under_rerout(val: any) {
    if (val == "Issues_with_Airport_infrastructure") {
      this.router.navigate(["/reject-claim"]);
      this.workType.delay_reason_check_in = val;
      this.formDataService.setWork(this.workType);
    } else if (val == "Airport_closure") {
      this.router.navigate(["/reject-claim"]);
      this.workType.delay_reason_check_in = val;
      this.formDataService.setWork(this.workType);
    } else if (val == "Wait_at_Immigration_Customs") {
      this.workType.delay_reason_check_in = val;
      this.formDataService.setWork(this.workType);
      this.router.navigate(["/reject-claim"]);
    }
    this.step_3_check_in_under_rerouted = true;
    this.describe_about_problem_under_rerouted = false;
  }

  prob_under_rerout(val: any) {
    if (val == "Damage_during_flight") {
      this.router.navigate(["/reject-claim"]);
      this.workType.delay_reason_tech_option = val;
      this.formDataService.setWork(this.workType);
    }
    this.step_3_tech_prob_under_rerouted = true;
    this.describe_about_problem_under_rerouted = false;
  }

  weather_under_rerout(val: any) {
    if (val != "Not_Sure") {
      this.router.navigate(["/reject-claim"]);
      this.workType.delay_reason_weather_option = val;
      this.formDataService.setWork(this.workType);
    }

    this.router.navigate(["/reject-claim"]);
    this.describe_about_problem_under_rerouted = false;
    this.step_3_weather_under_rerouted = true;
  }

  other_under_rerout(val: any) {
    if (val == "Air_Traffic_Control_restriction") {
      this.workType.delay_reason_other_option = val;
      this.formDataService.setWork(this.workType);
      this.router.navigate(["/reject-claim"]);
    } else if (val == "Medical_emergency") {
      this.workType.delay_reason_other_option = val;
      this.formDataService.setWork(this.workType);
      this.router.navigate(["/reject-claim"]);
    }
    this.describe_about_problem_under_rerouted = false;
    this.step_3_other_under_rerouted = true;
  }

  back_b_under_rerouted() {
    let backvalchange = "";
    this.workType.backprocessvalue = backvalchange;
    this.formDataService.setWork(this.workType);
    this.describe_about_problem_under_rerouted = true;

    if (this.workType.delay_reason_other_option != "") {
      this.workType.delay_reason_other_option = "";
      this.step_3_other_under_rerouted = false;
    } else if (this.workType.delay_reason_weather_option != "") {
      this.workType.delay_reason_weather_option = "";
      this.step_3_weather_under_rerouted = false;
    } else if (this.workType.delay_reason_tech_option != "") {
      this.workType.delay_reason_tech_option = "";
      this.step_3_tech_prob_under_rerouted = false;
    } else if (this.workType.delay_reason_check_in != "") {
      this.workType.delay_reason_check_in = "";
      this.step_3_check_in_under_rerouted = false;
    } else {
    }
  }

  nxt_frm_under_rerouted() {
    this.describe_about_problem_under_rerouted = true;
    this.alter_option_under_rerouted = false;
    this.formDataService.setWork(this.workType);
  }

  tech_op_returned_departure() {
    this.flight_delay_reason = true;
    this.step_3_tech_prob_under_return_departure = false;
    this.formDataService.setWork(this.workType);
  }

  weather_op_returned_departure() {
    this.flight_delay_reason = true;
    this.step_3_weather_under_return_departure = false;
    this.formDataService.setWork(this.workType);
  }

  other_op_returned_departure() {
    let thiredstepval = "3page1step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.flight_delay_reason = true;
    this.step_3_other_under_return_departure = false;
  }

  prob_under_returned_departure(val: any) {
    if (val == "Damage_during_flight") {
      this.router.navigate(["/reject-claim"]);
      this.workType.delay_reason_tech_option = val;
      this.formDataService.setWork(this.workType);
    }
    this.step_3_tech_prob_under_return_departure = true;
    this.describe_about_problem_under_returned_departure = false;
  }

  weather_under_returned_departure(val: any) {
    if (val != "Not_Sure") {
      this.router.navigate(["/reject-claim"]);
      this.workType.delay_reason_weather_option = val;
      this.formDataService.setWork(this.workType);
    }
    this.router.navigate(["/reject-claim"]);
    this.step_3_weather_under_return_departure = true;
    this.describe_about_problem_under_returned_departure = false;
  }
  other_under_returned_departure(val: any) {
    if (val == "Medical_emergency") {
      this.workType.delay_reason_other_option = val;
      this.formDataService.setWork(this.workType);
      this.router.navigate(["/reject-claim"]);
    }
    let thiredstepval = "3page2step";
    this.workType.progressvalue = thiredstepval;
    this.formDataService.setWork(this.workType);
    this.step_3_other_under_return_departure = true;
    this.describe_about_problem_under_returned_departure = false;
  }

  back_b_under_returned_departure() {
    let backvalchange = "";
    this.workType.backprocessvalue = backvalchange;
    this.formDataService.setWork(this.workType);
    this.describe_about_problem_under_returned_departure = true;
    this.step_3_tech_prob_under_return_departure = false;
  }

  nxt_frm_under_returned_departure() {
    this.alter_option_under_returned_departure = false;
    this.describe_about_problem_under_returned_departure = true;
    this.formDataService.setWork(this.workType);
  }

  a() {
    this.formDataService.setWork(this.workType);
  }

  flifht_re_delay_chck() {
    this.b2_2ndslide = false;
    this.step_3_check_in = true;
  }

  flifht_re_delay_tech() {
    this.b2_2ndslide = false;
    this.step_3_tech_prob = true;
  }

  flifht_re_delay_weat() {
    this.b2_2ndslide = false;
    this.step_3_weather = true;
  }

  flifht_re_delay_othe() {
    this.b2_2ndslide = false;
    this.step_3_other = true;
  }

  flifht_re_cancel_ckck() {
    this.step_3_check_in_under_cancel = true;
    this.flight_cancel_reason = false;
  }

  flifht_re_cancel_tech() {
    this.step_3_tech_prob_under_cancel = true;
    this.flight_cancel_reason = false;
  }

  flifht_re_cancel_weath() {
    this.step_3_weather_under_cancel = true;
    this.flight_cancel_reason = false;
  }

  flifht_re_cancel_other() {
    this.step_3_other_under_cancel = true;
    this.flight_cancel_reason = false;
  }

  flifht_re_routing_chck() {
    this.step_2_for_rerouted = false;
    this.step_3_check_in_under_rerouted = true;
  }

  flifht_re_routing_tech() {
    this.step_2_for_rerouted = false;
    this.step_3_tech_prob_under_rerouted = true;
  }

  flifht_re_routing_weather() {
    this.step_2_for_rerouted = false;
    this.step_3_weather_under_rerouted = true;
  }

  flifht_re_routing_other() {
    this.step_2_for_rerouted = false;
    this.step_3_other_under_rerouted = true;
  }

  Returned_Flight_reason_tech() {
    this.flight_delay_reason = false;
    this.step_3_tech_prob_under_return_departure = true;
  }

  Returned_Flight_reason_weather() {
    this.flight_delay_reason = false;
    this.step_3_weather_under_return_departure = true;
  }

  Returned_Flight_reason_other() {
    this.flight_delay_reason = false;
    this.step_3_other_under_return_departure = true;
  }

  clearFilter(newObj) {
    console.log(newObj);
    
    this.workType.actual_arrival_date = this.datepipe.transform(
      newObj,
      "dd.MM.yyy"
    );
    this.formDataService.setWork(this.workType);
    let timecheckhour: string = this.workType.actual_arrival_time.hour.toString();
    let timecheckmin: string = this.workType.actual_arrival_time.minute.toString();
    let timechecksec: string = this.workType.actual_arrival_time.second.toString();

    if (timecheckhour.length == 1) {
      timecheckhour = "0" + timecheckhour;
    } else {
      timecheckhour = timecheckhour;
    }
    if (timecheckmin.length == 1) {
      timecheckmin = "0" + timecheckmin;
    } else {
      timecheckmin = timecheckmin;
    }
    if (timechecksec.length == 1) {
      timechecksec = "0" + timechecksec;
    } else {
      timechecksec = timechecksec;
    }

    let time_field: string =
      timecheckhour + ":" + timecheckmin + ":" + timechecksec;
    let date_field = this.workType.actual_arrival_date;
    let date_time = date_field + " " + time_field;
    let u: string = this.formData.flightDetails.scheduled_arrival;

    if (this.formData.connectingflightsDetails != null) {
      u = this.formData.connectingflightsDetails[
        this.formData.connectingflightsDetails.length - 1
      ].connecting_flight_all_Details.scheduled_arrival;
    } else {
      u = this.formData.flightDetails.scheduled_arrival;
    }

    function datepipechange(date) {
      var changedate = date.split(" ");
      var changeddate = changedate[0].split(".");
      var timecheck = changedate[1].split(":");
      var timecheckhour = timecheck[0];
      var timecheckmin = timecheck[1];
      var timechecksec = timecheck[2];

      if (timecheckhour.length == 1) {
        timecheckhour = "0" + timecheckhour;
      } else {
        timecheckhour = timecheckhour;
      }
      if (timecheckmin.length == 1) {
        timecheckmin = "0" + timecheckmin;
      } else {
        timecheckmin = timecheckmin;
      }
      if (timechecksec.length == 1) {
        timechecksec = "0" + timechecksec;
      } else {
        timechecksec = timechecksec;
      }
      changeddate =
        changeddate[2] +
        "-" +
        changeddate[1] +
        "-" +
        changeddate[0] +
        "T" +
        timecheckhour +
        ":" +
        timecheckmin +
        ":" +
        timechecksec;

      return changeddate;
    }

    const date_time_afterchange = datepipechange(date_time);
    const u_time_afterchange = datepipechange(u);
    let diffInMs: number =
      Date.parse(date_time_afterchange) - Date.parse(u_time_afterchange);
    let diffInHours: number = diffInMs / 1000 / 60 / 60;
    let DiffInHours1: any = diffInHours;
    let time_in_hour: string = DiffInHours1.toString().split(".");
    let time_in_hr1 = "." + time_in_hour[1];

    if (time_in_hour[1] == undefined) {
      time_in_hr1 = "." + 0;
    }
    let time_in_hr2: any = time_in_hr1;
    let DelayyTime = time_in_hr2 * 60;
    if (DiffInHours1 == 0) {
      DelayyTime = 0;
    }
    let Detay_hours_minutes =
      time_in_hour[0] + " " + "hours" + " " + DelayyTime + " " + "minutes";
    this.Delay_hours_minutes = Detay_hours_minutes;
    this.timediffInMs = DiffInHours1;
    this.min = DelayyTime;
    this.hr = time_in_hour[0];

    if (this.hr < 0) {
    } else {
      this.btndisable = false;
    }

    this.formDataService.setWork(this.workType);
  }

  clearFilter_for_time(newObj) {
    let timecheckhour: string = newObj.hour.toString();
    let timecheckmin: string = newObj.minute.toString();
    let timechecksec: string = newObj.second.toString();

    if (timecheckhour.length == 1) {
      timecheckhour = "0" + timecheckhour;
    } else {
      timecheckhour = timecheckhour;
    }
    if (timecheckmin.length == 1) {
      timecheckmin = "0" + timecheckmin;
    } else {
      timecheckmin = timecheckmin;
    }
    if (timechecksec.length == 1) {
      timechecksec = "0" + timechecksec;
    } else {
      timechecksec = timechecksec;
    }

    let timestring: string =
      timecheckhour + ":" + timecheckmin + ":" + timechecksec;
    this.workType.actual_arrival_time = timestring;
    this.formDataService.setWork(this.workType);

    const datepipechange = (date) => {
      var changedate = date.split(" ");
      var changeddate = changedate[0].split(".");
      var timecheck = changedate[1].split(":");
      var timecheckhour = timecheck[0];
      var timecheckmin = timecheck[1];
      var timechecksec = timecheck[2];

      if (timecheckhour.length == 1) {
        timecheckhour = "0" + timecheckhour;
      } else {
        timecheckhour = timecheckhour;
      }
      if (timecheckmin.length == 1) {
        timecheckmin = "0" + timecheckmin;
      } else {
        timecheckmin = timecheckmin;
      }
      if (timechecksec.length == 1) {
        timechecksec = "0" + timechecksec;
      } else {
        timechecksec = timechecksec;
      }
      changeddate =
        changeddate[2] +
        "-" +
        changeddate[1] +
        "-" +
        changeddate[0] +
        "T" +
        timecheckhour +
        ":" +
        timecheckmin +
        ":" +
        timechecksec;
      return changeddate;
    };
    if (this.workType.actual_arrival_date == "N/A") {
      var str = this.formData.flightDetails.actual_arrival;
      if (this.formData.connectingflightsDetails != null) {
        str = this.formData.connectingflightsDetails[
          this.formData.connectingflightsDetails.length - 1
        ].connecting_flight_all_Details.actual_arrival;
      } else {
        str = this.formData.flightDetails.actual_arrival;
        if (str == "N/A") {
          str = this.formData.flightDetails.scheduled_arrival;
        }
      }

      var res = str.split(" ");
      var ab = res[0];
      let ddd = ab;
      let time_f = this.workType.actual_arrival_time;
      let date_and_ti = res[0] + " " + time_f;
      let davl = ddd;
      let devlspilit = davl.split(".");
      let devlsplitvalue = devlspilit[0];

      if (this.formData.manual_entry_without_variflight_from != null) {
      } else {
      }

      const date_time_afterchange = datepipechange(date_and_ti);
      const u_time_afterchange = datepipechange(str);

      let diffInMs: number =
        Date.parse(date_time_afterchange) - Date.parse(u_time_afterchange);

      let diffInHours: number = diffInMs / 1000 / 60 / 60;
      let DiffInHours1: any = diffInHours;
      let time_in_hour: string = DiffInHours1.toString().split(".");
      let minutecal: any;
      if (time_in_hour[1] == undefined) {
        minutecal = 0;
      } else {
        minutecal = time_in_hour[1];
      }
      let time_in_hr1 = "." + minutecal;
      let time_in_hr2: any = time_in_hr1;
      let DelayyTime = time_in_hr2 * 60;
      let Detay_hours_minutes =
        time_in_hour[0] + " " + "hours" + " " + DelayyTime + " " + "minutes";
      this.Delay_hours_minutes = Detay_hours_minutes;
      this.timediffInMs = DiffInHours1;
      this.min = DelayyTime;
      this.hr = time_in_hour[0];

      if (this.hr < 0) {
      } else {
        this.btndisable = false;
      }

      this.formDataService.setWork(this.workType);
    } else {
      this.formDataService.setWork(this.workType);
      let actual_ari_date_time = this.formData.flightDetails.actual_arrival;
      if (actual_ari_date_time == "N/A") {
        actual_ari_date_time = this.formData.flightDetails.scheduled_arrival;
      }
      let date_time = actual_ari_date_time.split(" ");
      let inid_format = actual_ari_date_time;
      this.formDataService.setWork(this.workType);
      let date_field = this.datepipe.transform(
        this.workType.actual_arrival_date,
        "dd.MM.yyyy"
      );

      let date_split_var = this.formData.flightDetails.actual_arrival;
      if (date_split_var == "N/A") {
        date_split_var = this.formData.flightDetails.scheduled_arrival;
      }
      const sp = date_split_var.split(" ");
      if (date_field == null) {
        date_field = sp[0];
      }
      let time_field = this.workType.actual_arrival_time;
      let str = inid_format;
      let c = str.substr(str.indexOf("/") + 1);
      let date_and_time = date_field + " " + time_field;
      let datesplitcall3 = this.formData.flightDetails.scheduled_arrival.split(
        " "
      );
      if (this.formData.connectingflightsDetails != null) {
        datesplitcall3 = this.formData.connectingflightsDetails[
          this.formData.connectingflightsDetails.length - 1
        ].connecting_flight_all_Details.scheduled_arrival;
      } else {
        datesplitcall3 = this.formData.flightDetails.scheduled_arrival;
      }
      if (this.formData.manual_entry_without_variflight_from != null) {
      } else {
      }
      const date_time_afterchange = datepipechange(date_and_time);
      const u_time_afterchange = datepipechange(datesplitcall3);

      let diffInMs: number =
        Date.parse(date_time_afterchange) - Date.parse(u_time_afterchange);
      let diffInHours: number = diffInMs / 1000 / 60 / 60;
      let DiffInHours1: any = diffInHours;
      let time_in_hour: string = DiffInHours1.toString().split(".");
      let time_in_hr1 = "." + time_in_hour[1];
      let time_in_hr2: any = time_in_hr1;
      let DelayyTime = time_in_hr2 * 60;
      if (time_in_hr1 == ".undefined") {
        DelayyTime = 0;
      }
      let Detay_hours_minutes =
        time_in_hour[0] + " " + "hours" + " " + DelayyTime + " " + "minutes";
      this.Delay_hours_minutes = Detay_hours_minutes;
      this.timediffInMs = DiffInHours1;
      this.min = DelayyTime;
      this.hr = time_in_hour[0];
      if (this.hr < 0) {
      } else {
        this.btndisable = false;
      }
      this.workType.actual_arrival_time = time_field;
      this.formDataService.setWork(this.workType);
    }
  }
}
