import { Component, OnInit, Input } from "@angular/core";
import { FormDataService } from "./data/formData.service";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { Meta, Title } from "@angular/platform-browser";

declare var gtag;

@Component({
  selector: "multi-step-wizard-app",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  title = "Claimflights";
  metacontent: any;

  @Input() formData;

  constructor(
    private formDataService: FormDataService,
    private router: Router,
    private metaTagService: Meta,
    private titleService: Title
  ) {
   
  }

  ngOnInit() {
    const navEndEvents = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    if (window.location.origin == "https://forms.claimflights.it") {
      navEndEvents.subscribe((event: NavigationEnd) => {
        gtag("config", "UA-140715337-1", {
          page_path: event.urlAfterRedirects,
        });
      });
    } else if (window.location.origin == "https://forms.claimflights.ro") {
      navEndEvents.subscribe((event: NavigationEnd) => {
        gtag("config", "UA-140712932-1", {
          page_path: event.urlAfterRedirects,
        });
      });
    } else if (window.location.origin == "https://forms.claimflights.pl") {
      navEndEvents.subscribe((event: NavigationEnd) => {
        gtag("config", "UA-96981178-1", {
          page_path: event.urlAfterRedirects,
        });
      });
    } else if (window.location.origin == "https://forms.claimflights.com") {
      navEndEvents.subscribe((event: NavigationEnd) => {
        gtag("config", "UA-62727165-1", {
          page_path: event.urlAfterRedirects,
        });
      });
    } else if (window.location.origin == "https://forms.claimflights.de") {
      navEndEvents.subscribe((event: NavigationEnd) => {
        gtag("config", "UA-58297807-1", {
          page_path: event.urlAfterRedirects,
        });
      });
    } else if (window.location.origin == "https://forms.claimflights.at") {
      navEndEvents.subscribe((event: NavigationEnd) => {
        gtag("config", "UA-100823019-1", {
          page_path: event.urlAfterRedirects,
        });
      });
    } else if (window.location.origin == "https://forms.claimflights.co.uk") {
      navEndEvents.subscribe((event: NavigationEnd) => {
        gtag("config", "UA-62723394-1", {
          page_path: event.urlAfterRedirects,
        });
      });
    } else if (window.location.origin == "https://forms.claimflights.fr") {
      navEndEvents.subscribe((event: NavigationEnd) => {
        gtag("config", "UA-63677090-1", {
          page_path: event.urlAfterRedirects,
        });
      });
    } else if (
      window.location.origin == "https://forms.claimflights.es" ||
      window.location.origin == "https://esforms.claimflights.com"
    ) {
      navEndEvents.subscribe((event: NavigationEnd) => {
        gtag("config", "UA-63761055-1", {
          page_path: event.urlAfterRedirects,
        });
      });
    } else if (window.location.origin == "https://forms.claimflights.ru") {
      navEndEvents.subscribe((event: NavigationEnd) => {
        gtag("config", "UA-84766821-1", {
          page_path: event.urlAfterRedirects,
        });
      });
    }
    ///constructer////////////
    
    this.formData = this.formDataService.getFormData();
    if (window.location.origin == "https://forms.claimflights.it") {
      this.metacontent =
        "Inserisci i dettagli del tuo volo e ottieni denaro velocemente in base alla legge EU261/2004 sulla compensazione per ritardi di volo.";
      this.title =
        "Invia la tua richiesta di risarcimento per ritardo di volo in soli 2 minuti";
    } else if (window.location.origin == "https://forms.claimflights.ro") {
      this.metacontent =
        "Completați detaliile zborului dvs. și obțineți bani rapid în conformitate cu legea UE 261/2004 privind despăgubirile pentru zbor cu întârziere.";
      this.title =
        "Trimiteți reclamația pentru zbor cu întârziere în doar 2 minute";
    } else if (window.location.origin == "https://forms.claimflights.pl") {
      this.metacontent =
        "Podaj swoje dane dotyczące lotu i szybko uzyskaj pieniądze zgodnie z prawem UE 261/2004 dotyczącym odszkodowania za opóźniony lot";
      this.title =
        "Złóż wniosek o odszkodowanie za opóźniony lot w zaledwie 2 minuty";
    } else if (window.location.origin == "https://forms.claimflights.com") {
      this.metacontent =
        "Fill in your flight details and get money fast according to EU261/2004 flight delay compensation law.";
      this.title =
        "Submit your Claim for Flight Delay Compensation in just 2 min";
    } else if (window.location.origin == "https://forms.claimflights.de") {
      this.metacontent =
        "Füllen Sie Ihre Flugdaten aus und bekommen Sie schnell eine Entschädigung nach der EU Fluggastrechteverordnung  Nr. 261/2004";
      this.title =
        "Geben Sie Ihren Entschädigungsfall betreffend Flugverspätung in 2 Minuten ein";
    } else if (window.location.origin == "https://forms.claimflights.at") {
      this.metacontent =
        "Füllen Sie Ihre Flugdaten aus und bekommen Sie schnell eine Entschädigung nach der EU Fluggastrechteverordnung  Nr. 261/2004";
      this.title =
        "Geben Sie Ihren Entschädigungsfall betreffend Flugverspätung in 2 Minuten ein";
    } else if (window.location.origin == "https://forms.claimflights.co.uk") {
      this.metacontent =
        "Fill in your flight details and get money fast according to EU261/2004 flight delay compensation law.";
      this.title =
        "Submit your Claim for Flight Delay Compensation in just 2 min";
    } else if (window.location.origin == "https://forms.claimflights.fr") {
      this.metacontent =
        "Remplissez les détails de votre vol et obtenez de l'argent rapidement conformément au règlement des droits de passagers aériens EU261 / 2004.";
      this.title =
        "Insérez votre demande d'indemnisation pour retard de vol en seulement 2 minutes";
    } else if (window.location.origin == "https://forms.claimflights.es" || window.location.origin == "esforms.claimflights.com") {
      this.metacontent =
        "Rellene la información de su vuelo y consiga su dinero rápidamente según la ley EU261/2004 sobre indemnizaciones por vuelos retrasados";
      this.title =
        "Envíe su Reclamación por Indemnización de Retraso de Vuelo en solo 2 min";
    } else if (window.location.origin == "https://forms.claimflights.ru") {
      this.metacontent =
        "Введите данные полёта и получите быстро компенсацию в соответствии с Регламентом ЕС 261.";
      this.title =
        "Подайте заявку на компенсацию за задержку рейса за 2 минуты.";
    } else if (window.location.origin == "https://devforms.claimflights.de") {
      this.metacontent =
        "Füllen Sie Ihre Flugdaten aus und bekommen Sie schnell eine Entschädigung nach der EU Fluggastrechteverordnung  Nr. 261/2004";
      this.title =
        "Geben Sie Ihren Entschädigungsfall betreffend Flugverspätung in 2 Minuten ein";
    } else {
      this.metacontent =
        "Fill in your flight details and get money fast according to EU261/2004 flight delay compensation law.";
      this.title =
        "Submit your Claim for Flight Delay Compensation in just 2 min";
    }

    this.metaTagService.addTags([
      {
        name: "ClaimFlights",
        content: this.metacontent,
      },
    ]);

    this.titleService.setTitle(this.title);
  }

  getLanguageFromDomain() {
    let url = new URL(window.location.origin);
    let language = "com"; //Here Com act as English
    if (
      url.host.split(".")[0].includes("es") &&
      url.host.split(".")[2].includes("es")
    ) {
      language = "es";
    }
    return language;
  }
}
