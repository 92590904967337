import { Component, OnInit } from "@angular/core";
import { FormDataService } from "../data/formData.service";
import { Router, NavigationEnd } from '@angular/router';
import { FormData } from "../data/formData.model";
import { filter } from 'rxjs/operators';

@Component({
  selector: "msw-navbar",
  templateUrl: "./navbar.component.html",
})
export class NavbarComponent implements OnInit {
  bntStyle: any;
  public formdata: FormData;
  public progress_page:number =  0;
  progress_page_string: string;
  constructor(private formDataService: FormDataService, private router: Router) {}

  ngOnInit() {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))  
    .subscribe((event: NavigationEnd) => {
      console.log("progress_page---",event)
      if(event?.url == '/enter_flight_details'){
        this.progress_page  = 2;    
      }
      else if(event?.url == '/Problem'){
        this.progress_page  = 3;    
      }
      else if(event?.url == '/address'){
        this.progress_page  = 4;    
      }
      else if(event?.url == '/signature'){
        this.progress_page  = 5;    
      }
      else{
      console.log("progress_page",event)
        this.progress_page = event?.id;
      }
      this.formdata = this.formDataService.getFormData();
      console.log("this.formdata",this.formdata);
      console.log(this.progress_page);
      console.log(this.formdata.progressvalue);
    });
  }

  stateFlag = true;
  calculateClasses() {
    this.formdata = this.formDataService.getFormData();
    this.bntStyle = this.formdata.progressvalue;
    console.log(this.bntStyle);
    
    if (this.bntStyle == "1page1step") {
      return {
        firststepfirstcom: this.stateFlag,
      };
    } else if (this.bntStyle == "1page2step") {
      return {
        firststepsecondcom: this.stateFlag,
      };
    } else if (this.bntStyle == "3page1step") {
      return {
        secondstepfirstcom: this.stateFlag,
      };
    } else if (this.bntStyle == "3page2step") {
      return {
        firststepthiredcom: this.stateFlag,
      };
    } else if (this.bntStyle == "4page1step") {
      return {
        firststepforthcom: this.stateFlag,
      };
    } else if (this.bntStyle == "4page2step") {
      return {
        secondstepforthcom: this.stateFlag,
      };
    } else if (this.bntStyle == "4page3step") {
      return {
        thiredstepforthcom: this.stateFlag,
      };
    } else if (this.bntStyle == "4page4step") {
      return {
        totalnewstep: this.stateFlag,
      };
    } else {
      return {
        liner: this.stateFlag,
      };
    }
  }
}
