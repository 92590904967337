import { Component, OnInit, Input } from "@angular/core";
import { FormData } from "../data/formData.model";
import { FormDataService } from "../data/formData.service";
import { DatePipe } from "@angular/common";

import { TranslateService } from "@ngx-translate/core";
import { gtag, initDataLayer, install } from 'ga-gtag';
@Component({
  selector: "mt-wizard-result",
  templateUrl: "./result.component.html",
})
export class ResultComponent implements OnInit {
  title = "Thanks for staying tuned!";
  @Input() formData: FormData;
  isFormValid: boolean = false;
  public lan: any;
  public lane: any;
  public caseStatus: boolean = false;
  amnt: any;

  constructor(
    public translate: TranslateService,
    private formDataService: FormDataService
  ) {
    this.formData = this.formDataService.getFormData();
    console.log(this.formData);
    if(this.formData.compensation_details_with_other_passenger.length){
      this.amnt = this.formData.compensation_details_with_other_passenger[0].otherpas_success_amount;
    }
    else{
      this.amnt = this.formData.compensation_details[0].success_amount;
    }
    
    this.lane = this.formData.language;
    if (this.lane == "") {
      this.lane = "com";
    }
    translate.setDefaultLang(this.lane);


  }

  ngOnInit() {
    this.formData = this.formDataService.getFormData();
    this.isFormValid = this.formDataService.isFormValid();
    let inportantname = this.formData.Actual_Arrival_Options;

    if (this.formData.CaseNumber != null) {
      this.caseStatus = true;
    }

    if (inportantname == "") {
      window.location.href = "/enter_flight_details";
    }
    this.tag();

  }

  tag(){
    console.log("hey",this.amnt)
//     if (window.location.origin == "https://forms.claimflights.fr") {
//       console.log("k");
//  gtag('event', 'conversion', {
//   "send_to": 'G-078YB9GL8W',
//   "value": this.amnt, // Conversion value (the amount of revenue)
//   "currency": "EUR"
//  });
//  }

    console.log(this.formData.CaseNumber);
    
    if (window.location.origin == "https://forms.claimflights.fr") {
      console.log("k");
 gtag('event', 'conversion', {
  "send_to": 'AW-972356476/KBRBCPuG6ZgZEPz2088D',
  "value": this.amnt, // Conversion value (the amount of revenue)
  "currency": "EUR",
  'transaction_id': this.formData.CaseNumber
 });

 }
  }

  submit() {
    this.formData = this.formDataService.resetFormData();
    this.isFormValid = false;
  }
}
