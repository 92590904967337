import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from "@angular/forms";
import { otherpassenger, FormData } from "../data/formData.model";
import { FormDataService } from "../data/formData.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-other-passenger",
  templateUrl: "./other-passenger.component.html",
  styleUrls: ["./other-passenger.component.css"],
})
export class OtherPassengerComponent implements OnInit {
  Otherpassenger: otherpassenger;
  formdata: FormData;
  public lan: any;
  public addothpass: boolean = false;
  public myForm: UntypedFormGroup;

  constructor(
    public translate: TranslateService,
    private _fb: UntypedFormBuilder,
    private formDataService: FormDataService
  ) {
    this.formdata = this.formDataService.getFormData();
    this.lan = this.formdata.language;
    translate.setDefaultLang(this.lan);
  }

  ngOnInit() {
    this.Otherpassenger = this.formDataService.getotherpassenger();
    this.myForm = this._fb.group({
      languages: this._fb.array([this.initpass()]),
    });
  }
  initpass() {
    return this._fb.group({
      other_title: [""],
      other_first_name: [""],
      other_last_name: [""],
      other_pass_status: [""],
    });
  }

  addLanguage() {
    const control = <UntypedFormArray>this.myForm.controls["languages"];
    control.push(this.initpass());
  }

  removeLanguage(i: number) {
    const control = <UntypedFormArray>this.myForm.controls["languages"];
    control.removeAt(i);
  }

  save(i) {
    const control = <UntypedFormArray>this.myForm.controls["languages"];
    let index_lenth = control.length - 1;
    if (this.myForm.value.languages[index_lenth].other_last_name != "") {
      this.myForm.value.languages[index_lenth].other_pass_status = "not";
    }
    this.Otherpassenger.otherpass = this.myForm.value.languages;
    this.formDataService.setotherpassenger(this.Otherpassenger);
    this.addothpass = true;
  }
}
