import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { environment } from '../../src/environments/environment';

import {
  FormData,
  Personal,
  Address,
  Work,
  connectingflights,
  alternetiv,
} from "../app/data/formData.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private _http: HttpClient) { }

  For_Formdata(formdata: FormData, _url) {
    return this._http
      .post<any>(_url, formdata)
      .pipe(catchError(this.errorHandler));
  }

  For_autocomplete() {
    const _url =
    environment.API_BASE_URL + "airport_autocomplete.php";
    return this._http.get<any>(_url).pipe(catchError(this.errorHandler));
  }

  For_Personal(personal: Personal, _url) {
    return this._http.get<any>(_url).pipe(catchError(this.errorHandler));
  }
  For_Address(address: Address) {
    const _url = "";
    return this._http
      .post<any>(_url, address)
      .pipe(catchError(this.errorHandler));
  }

  For_Work(work: Work) {
    const _url = "";
    return this._http.post<any>(_url, Work).pipe(catchError(this.errorHandler));
  }
  For_connectingflights_manual(_url) {
    return this._http.get<any>(_url);
  }

  For_connectingflights(connectingflights: connectingflights, _url) {
    return this._http.get<any>(_url).pipe(catchError(this.errorHandler));
  }

  For_alternativeflights(alternetive: alternetiv, _url) {
    return this._http
      .get<any>(_url, { responseType: "json" })
      .pipe(catchError(this.errorHandler));
  }
  For_alternativebustrain(alternetive: alternetiv, _url) {
    return this._http
      .get<any>(_url, { responseType: "json" })
      .pipe(catchError(this.errorHandler));
  }
  For_manualentry_wihoutvariflight(personal: Personal, _url) {
    return this._http
      .get<any>(_url, { responseType: "json" })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
