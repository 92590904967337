import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Personal, FormData } from "../data/formData.model";
import { FormDataService } from "../data/formData.service";
import { HttpClient } from "@angular/common/http";
import { UserService } from "../user.service";
import { $ } from "protractor";
import { TranslateService } from "@ngx-translate/core";
import {
  NativeDateAdapter,
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "../date.adapter";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Observable, of } from "rxjs";
import { map, startWith, min } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { Injectable } from "@angular/core";
import { environment } from "../../../src/environments/environment";

// check for new branch update
@Injectable({
  providedIn: "root",
})
export class Service {
  service_url = environment.API_BASE_URL;

  constructor(
    private httpClient: HttpClient,
    private formDataService: FormDataService
  ) {
    this.formdata = this.formDataService.getFormData();
    // if (this.service_url == "http://localhost:4200") {
    //   this.service_url = "https://devforms.claimflights.com";
    // }
  }


  jokes = [];
  airline_arr = [];
  formdata: FormData;
  getAirline(val:any) {
    console.log(val);
    
    if (val == "") {
      return (this.airline_arr = undefined);
    } else {
      return this.httpClient
        .get<any>(
          this.service_url +
            "airline_autocomplete.php?api_key=cf-2019-1228-9c7b&keyword=" +
            val
        )
        .pipe(
          map((data) => {
            if (data) {
              if (data[0].name_of_airline == undefined) {
                setTimeout(() => {
                  var element = document.getElementById("mat-autocomplete-2");
                  if (element) {
                    element.classList.remove("mat-autocomplete-visible");
                    this.formdata.autodatacheck_status = true;
                  } else {
                    this.formdata.autodatacheck_status = false;
                  }
                }, 10);
              } else {
                this.formdata.autodatacheck_status = false;
              }
            }
            this.airline_arr = data;
            return this.airline_arr;
          })
        );
    }
  }

  getData(a) {
    if (a == "") {
    } else {
      return this.httpClient
        .get<any>(
          this.service_url +
            "airport_autocomplete.php?api_key=cf-2019-1228-9c7b&keyword=" +
            a
        )
        .pipe(
          map((data) => {
            this.jokes = data;
            if (data == null) {
            } else {
              if (data[0]) {
                if (data[0].iata_code === "Not Found") {
                  setTimeout(() => {
                    let element = document.getElementById("mat-autocomplete-1");
                    if (element == null) {
                      element = document.getElementById("mat-autocomplete-3");
                    } else {
                      element = document.getElementById("mat-autocomplete-4");
                    }
                    if (element) {
                      element.classList.remove("mat-autocomplete-visible");
                      this.formdata.autodatacheck_status = true;
                    } else {
                      this.formdata.autodatacheck_status = false;
                    }
                  }, 10);
                } else {
                  this.formdata.autodatacheck_status = false;
                }
              }
            }
            return this.jokes;
          })
        );
    }
  }
}

@Component({
  selector: "mt-wizard-personal",
  templateUrl: "./personal.component.html",
  styleUrls: ["./personal.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
@Component({
  selector: "mt-wizard-personal",
  templateUrl: "./personal.component.html",
  styleUrls: ["./personal.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class PersonalComponent implements OnInit {
  startDate = new Date();
  title = "Please tell us about yourself.";
  personal: Personal;
  formdata: FormData;
  form: any;
  public href: string = "";
  response: any;
  response_list: Array<any> = [];
  public show: boolean = false;
  public preform: boolean = false;
  public norform: boolean = false;
  public SCHEDULED: boolean = false;
  public connecting_flights_section: boolean = false;
  public Manual_Entry: boolean = true;
  public clickreason: string = "";
  public modefy_date_for: any;
  public connecting_fl_entry: any = true;
  public lan: any;
  public What_went_wrong: boolean = true;
  public SCHEDULED_manual_entry: boolean = false;
  public m_entry_bk_bttn: boolean = true;
  public mfield: boolean = false;
  public connecting_flights_section_bttn: boolean = false;
  public modefy_date_for_arr: any;
  public Autocomplete_json: any;
  public countries: any;
  public withoutdata: boolean = false;
  public withoutvari_entry: boolean = false;
  public withdata: boolean = false;
  public manual_entry_without_variflight_responce: boolean = false;
  public manual_entry_without_variflight_responce_val: any;
  public newresponsearr = [];
  public myvariable: any;
  public input_alert: any;
  public input_alert2: any;
  public manualformhide: boolean;
  public responsedatavariflight: any;
  jokes;
  jokes1;
  airline_arr;
  currentJoke = "";
  currentJoke1 = "";
  public a: any;
  public responsedata: any;
  service_url = environment.API_BASE_URL;

  constructor(
    public translate: TranslateService,
    public datepipe: DatePipe,
    private router: Router,
    private formDataService: FormDataService,
    private svc: UserService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private ngxService: NgxUiLoaderService,
    private datePipe: DatePipe,
    private service: Service
  ) 
  {
    this.href = this.router.url;
        console.log(this.router.url);
    this.personal = this.formDataService.getPersonal();
    this.formdata = this.formDataService.getFormData();
    const myvar = window.location.href;
    let url = new URL(myvar);
    let params = new URLSearchParams(url.search);
    const flightVar = params.get("flightNo");
    const dateVar = params.get("flightDate");
    // const flightVar = params.get("flightNo").replace(/[^A-Za-z0-9]/g, "");
    // const dateVar = this.datepipe.transform(params.get("flightDate"),"dd.MM.yyyy");

    if (flightVar == null || dateVar == null) {
      this.router.navigate(["/enter_flight_details"]);
    }
    let form_url = window.location.hostname;
    let final_lan;
    console.log(form_url);
    console.log(this.service_url);
    
    if (this.service_url == "http://localhost:4200") {
      // this.service_url = "https://devforms.claimflights.com";
      // final_lan = "com";
      // final_lan = "es";
    } else {
      let form_url_split = form_url.split(".claimflights.");
      console.log(form_url_split);
      
      if (
        form_url_split[0] == "forms" ||
        form_url_split[0] == "devforms" ||
        form_url_split[0] == "testing" ||
        form_url_split[0] == "liveforms"
      ) {
        console.log(final_lan);
        
        final_lan = form_url_split[1];
      } else if (form_url_split[0] == "bgforms") {
        final_lan = "BG";
        console.log(final_lan);

      } else if (form_url_split[0] == "esforms") {
        final_lan = "es";
        console.log(final_lan);

      } else {
        final_lan = "com";
        // final_lan = "es";
        console.log(final_lan);

      }
    }
    console.log(final_lan);

    this.translate.setDefaultLang(final_lan);
    this.personal.language = final_lan;
    this.formDataService.setPersonal(this.personal);
    this.formDataService.setPersonal(this.personal);
    // this.getairL()
  }

  keywordAir = 'name_of_airline';
  selectEvent(item) {
    console.log(item);
    
    // do something with selected item
  }

  onChangeSearch(val: string) {
    console.log(val);
    
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  
  onFocused(e){
    // do something when input is focused
  }
  getairL(){
    this.http.get(this.service_url +"airline_autocomplete.php?api_key=cf-2019-1228-9c7b&keyword=").subscribe((emp:any) => {
      if(emp.length){
        this.airline_arr = emp;
        console.log(this.airline_arr);
        
      }
    });
  }
  consFN(){

  }

  filterforAirline(item) {
    console.log(item);
      this.a = item;
    
    if (typeof item === "string") {
      this.formdata.autodatacheck_status = true;
      this.airline_arr = this.getAirline(item);
      return 0;
    } else {
      this.a = item;
      this.airline_arr = this.getAirline(item);
      if (this.personal.flightDetails.rejected_airline) {
        this.personal.flightDetails.rejected_airline = this.a.rejected_airline;
        this.personal.rejected_airline = this.a.rejected_airline;
        this.formDataService.setPersonal(this.personal);
      }
    }
    this.a = item;
    if (this.a == "") {
    } else {
      this.jokes = this.service.getData(this.a).pipe(
        map((jokes) => {
          // if(jokes[0].iata_code === "Not Found"){
          //   return this.a = null;
          // }
          return this.filter(jokes);
        })
      );
      this.personal.field_manual_departure_form = this.currentJoke1;
      this.personal.field_manual_arrival_form = this.currentJoke;
    }
  }

  doFilter(item) {
    this.a = item;
    if (this.a == "") {
    } else {
      this.jokes = this.service.getData(this.a).pipe(
        map((jokes) => {
          // if(jokes[0].iata_code === "Not Found"){
          //   return this.a = null;
          // }
          return this.filter(jokes);
        })
      );
      this.personal.field_manual_departure_form = this.currentJoke1;
      this.personal.field_manual_arrival_form = this.currentJoke;
    }
  }
  doFilter1(item) {
    this.a = item;
    if (this.a == "") {
    } else {
      this.jokes1 = this.service.getData(this.a).pipe(
        map((jokes1) => {
          if (jokes1[0].iata_code === "Not Found") {
            return (this.a = null);
          }
          return this.filter(jokes1);
        })
      );
      this.personal.field_manual_departure_form = this.currentJoke1;
      this.personal.field_manual_arrival_form = this.currentJoke;
    }
  }

  filter(values) {
    if (values[0].iata_code === "Not Found") {
      console.log("not found");
      return;
    }
    return values.filter(
      (joke) =>
        joke.name.toLowerCase().includes(this.a) ||
        joke.iata_code.toLowerCase().includes(this.a) ||
        joke.city.toLowerCase().includes(this.a) ||
        joke.city_code.toLowerCase().includes(this.a)
    );
  }

  showFn(airline_arr:any){
    console.log(airline_arr);
    
    if (airline_arr) {
      if (airline_arr.name_of_airline == "Not Found") {
        return null;
      } else {
        return airline_arr ? airline_arr.name_of_airline : null;
      }
    }
  }
  displayFn(jokes): string | undefined {
    if (jokes) {
      if (jokes.iata_code == "Not Found") {
        return "Not Found";
      } else {
        return jokes ? jokes.name + " (" + jokes.iata_code + ") " : undefined;
      }
    }
  }
  displayFn1(jokes1): string | undefined {
    if (jokes1) {
      if (jokes1.iata_code == "Not Found") {
        return "Not Found";
      } else {
        return jokes1
          ? jokes1.name + " (" + jokes1.iata_code + ") "
          : undefined;
      }
    }
  }

  ngOnInit() {
    this.spinner.show();
    this.personal.test = "testing number";
    this.formDataService.setPersonal(this.personal);
    this.href = this.router.url;
    let para = this.href.split("enter_flight_details");
    if (para[1] == "") {
      this.preform = true;
      this.spinner.hide();
    } else {
      this.norform = true;
      let stringToSplit = this.router.url;
      let x = stringToSplit.split("=");
      let y = x[1].split("&");
      if (y[0] == "" || x[2] == "") {
        this.router.navigate(["/enter_flight_details"]);
        this.What_went_wrong = false;
        this.SCHEDULED = false;
        this.Manual_Entry = false;
        this.connecting_fl_entry = false;
        this.show = !this.show;
        this.m_entry_bk_bttn = true;
        this.spinner.hide();
      } else {
        // let ft =y[0].replace(/[^A-Za-z0-9]/g, "");
        let ft = decodeURIComponent(y[0]);
        let ftFinal = ft.replace(/[^A-Za-z0-9]/g, "");
        let dt = x[2];
        console.log(ft);
        console.log(ftFinal);
        
        let z =
          this.service_url +
          "api/v1/variflight/?api_key=cf-2019-1228-9c7b&flightNo=" +
          ftFinal +
          "&flightDate=" +
          x[2]
          // dt
          // this.datepipe.transform(x[2],"dd.MM.yyyy");
          console.log("urllllzzzzzzzzzzzzz",z);
          
        this.svc.For_Personal(this.personal, z).subscribe((response) => {
          this.response = response;
          this.responsedatavariflight = response;
          if (
            this.responsedatavariflight[1] == "Ao data" ||
            this.responsedatavariflight[1] == "Vo data" ||
            this.responsedatavariflight[1] == "Arong flight number format" ||
            this.responsedatavariflight.FlightDepcode == null ||
            this.responsedatavariflight.FlightDep == null ||
            this.responsedatavariflight.scheduled_departure == null ||
            this.responsedatavariflight.FlightArrcode == null ||
            this.responsedatavariflight.FlightArr == null ||
            this.responsedatavariflight.scheduled_arrival == null ||
            this.responsedatavariflight[1] ==
              "Vrong departure airport format" ||
            this.responsedatavariflight[1] == "Vo data permissions"
          ) {
            this.What_went_wrong = false;
            this.SCHEDULED = false;
            this.Manual_Entry = false;
            this.connecting_fl_entry = false;
            this.show = !this.show;
            this.m_entry_bk_bttn = true;
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.SCHEDULED = true;
            this.modefy_date_for =
              this.responsedatavariflight.scheduled_departure;
            this.modefy_date_for_arr =
              this.responsedatavariflight.scheduled_arrival;
          }
          this.personal.flightDetails = this.responsedatavariflight;
        this.formDataService.setPersonal(this.personal);

        });
        this.personal = this.formDataService.getPersonal();
      }
    }
  }
  getAirline(val:any) {
    console.log(val);
    
    if (!val) {
      return (this.airline_arr = null);
    } else {
    console.log(val);

    return this.http.get(this.service_url +"airline_autocomplete.php?api_key=cf-2019-1228-9c7b&keyword="+ val).subscribe((res:any) => {

      // this.http.get<any>(this.service_url +"airline_autocomplete.php?api_key=cf-2019-1228-9c7b&keyword=" + val).pipe(map((res:any) => {
        console.log(res);
        
            if (res) {
              if (!res.length) {
                setTimeout(() => {
                  var element = document.getElementById("mat-autocomplete-2");
                  if (element) {
                    // element.classList.remove("mat-autocomplete-visible");
                    this.formdata.autodatacheck_status = true;
                  } else {
                    this.formdata.autodatacheck_status = false;
                  }
                }, 10);
              } else {
                this.formdata.autodatacheck_status = false;
              }
              this.airline_arr = res;
              console.log(this.airline_arr);
              
              return this.airline_arr;
            }

          })
        ;
    }
  }





  manual_entry_without_variflight() {
    // let manual_entry_without_variflight_airline =
    //   this.personal.manual_entry_without_variflight_airline;
    let manual_entry_without_variflight_from =
      this.personal.manual_entry_without_variflight_from;
    let manual_entry_without_variflight_fromdate =
      this.personal.manual_entry_without_variflight_fromdate;
    let manual_entry_without_variflight_to =
      this.personal.manual_entry_without_variflight_to;
    let manual_entry_without_variflight_todate =
      this.personal.manual_entry_without_variflight_todate;

    let from_value =
      this.personal.manual_entry_without_variflight_fromtime.hour;
    if (from_value < 10) {
      from_value = "0" + from_value;
    }
    let from_value_min =
      this.personal.manual_entry_without_variflight_fromtime.minute;
    if (from_value_min < 10) {
      from_value_min = "0" + from_value_min;
    }

    let to_value = this.personal.manual_entry_without_variflight_totime.hour;
    if (to_value < 10) {
      to_value = "0" + to_value;
    }
    let to_value_min =
      this.personal.manual_entry_without_variflight_totime.minute;
    if (to_value_min < 10) {
      to_value_min = "0" + to_value_min;
    }
    let timestringfrom: string = from_value + ":" + from_value_min;
    let manual_entry_without_variflight_fromtime = timestringfrom;
    let timestring: string = to_value + ":" + to_value_min;
    let manual_entry_without_variflight_totime = timestring;

    if (this.personal.manual_entry_without_variflight_from == undefined) {
      this.router.navigate(["/enter_flight_details"]);
    }
    if (this.personal.manual_entry_without_variflight_to == undefined) {
      this.router.navigate(["/enter_flight_details"]);
    }

    let n = this.datePipe.transform(
      manual_entry_without_variflight_fromdate,
      "dd.MM.yyyy"
    );
    let m = this.datePipe.transform(
      manual_entry_without_variflight_todate,
      "dd.MM.yyyy"
    );

    let r =
      this.service_url +
      "train_bus.php?api_key=cf-2019-1228-9c7b&dep_airport=" +
      manual_entry_without_variflight_from.iata_code +
      "&arr_airport=" +
      manual_entry_without_variflight_to.iata_code +
      "&dep_date=" +
      n +
      "&arr_date=" +
      m +
      "&dep_time=" +
      manual_entry_without_variflight_fromtime +
      "&arr_time=" +
      manual_entry_without_variflight_totime +
      "&airline_name=" 
      // +
      // encodeURIComponent(
      //   this.personal.manual_entry_without_variflight_airline.name_of_airline
      // );

    this.svc
      .For_manualentry_wihoutvariflight(this.personal, r)
      .subscribe((response) => {
        this.response = response;
        // this.response.FlightCompany =
        //   this.personal.manual_entry_without_variflight_airline.name_of_airline;
        if (this.response == null) {
        } else {
          this.SCHEDULED = true;
          this.connecting_fl_entry = true;
          this.What_went_wrong = true;
          this.manual_entry_without_variflight_responce = false;
          this.personal.flightDetails = this.response;
          this.personal.flightDetails.FlightNo = "";
          this.formDataService.setPersonal(this.personal);
          this.withoutdata = false;
          this.withoutvari_entry = false;
          this.show = false;
        }
      });
      console.log(this.formdata);
      
  }

  save(form: any): boolean {
    if (!form.valid) {
      return false;
    }
    this.formDataService.setPersonal(this.personal);
    return true;
  }

  goToNext(form: any) {
    if (this.save(form)) {
      this.router.navigate(["/Problem"]);
    }
  }
  reversemanual() {
    document.getElementById("manual_entry_data_status").style.display = "none";
    this.withoutdata = true;
    this.withoutvari_entry = true;
    this.manualformhide = false;
    this.withdata = false;
  }

  toggle() {
    if (!this.show) {
      this.show = !this.show;
      this.SCHEDULED = false;
      this.What_went_wrong = false;
      this.Manual_Entry = false;
      this.m_entry_bk_bttn = true;
      this.connecting_fl_entry = false;
    } else {
    }
  }

  I_landed_on_time(form: any) {
    this.clickreason = "I_landed_on_time";
    this.personal.Actual_Arrival_Options = this.clickreason;
    if (this.save(form)) {
      this.router.navigate(["/reject-claim"]);
    }
  }

  My_flight_was_delayed(form: any) {
    let secondstepval = "1page2step";
    this.personal.progressvalue = secondstepval;
    this.formDataService.setPersonal(this.personal);
    this.clickreason = "My_flight_was_delayed";
    this.personal.Actual_Arrival_Options = this.clickreason;
    if (this.save(form)) {
      this.router.navigate(["/Problem"]);
    }
  }

  My_flight_was_cancelled(form: any) {
    let secondstepval = "1page2step";
    this.personal.progressvalue = secondstepval;
    this.formDataService.setPersonal(this.personal);
    this.clickreason = "My_flight_was_cancelled";
    this.personal.Actual_Arrival_Options = this.clickreason;
    if (this.save(form)) {
      this.router.navigate(["/Problem"]);
    }
  }

  I_was_denied_boarding(form: any) {
    let secondstepval = "1page2step";
    this.personal.progressvalue = secondstepval;
    this.formDataService.setPersonal(this.personal);
    this.clickreason = "I_was_denied_boarding";
    this.personal.Actual_Arrival_Options = this.clickreason;
    if (this.save(form)) {
      this.router.navigate(["/Problem"]);
    }
  }

  My_flight_was_rerouted(form: any) {
    let secondstepval = "1page2step";
    this.personal.progressvalue = secondstepval;
    this.formDataService.setPersonal(this.personal);
    this.clickreason = "My_flight_was_rerouted";
    this.personal.Actual_Arrival_Options = this.clickreason;
    if (this.save(form)) {
      this.router.navigate(["/Problem"]);
    }
  }

  Returned_to_the_departure_airport(form: any) {
    let secondstepval = "1page2step";
    this.personal.progressvalue = secondstepval;
    this.formDataService.setPersonal(this.personal);
    this.clickreason = "Returned_to_the_departure_airport";
    this.personal.Actual_Arrival_Options = this.clickreason;
    if (this.save(form)) {
      this.router.navigate(["/Problem"]);
    }
  }

  onSearchChange(searchValue: string) {}

  con() {
    this.formdata.avi = true;
    this.connecting_fl_entry = false;
    this.connecting_flights_section = true;
    this.What_went_wrong = false;
    this.SCHEDULED = false;
    this.connecting_flights_section_bttn = true;
    this.SCHEDULED_manual_entry = false;

    if (this.Manual_Entry == true) {
      this.Manual_Entry = false;
    }
  }
  back_landing_page_for_cf() {
    this.formdata.avi = false;
    this.connecting_fl_entry = true;
    this.connecting_flights_section = false;
    this.What_went_wrong = true;
    this.SCHEDULED = true;
    this.connecting_flights_section_bttn = false;
    this.SCHEDULED_manual_entry = true;

    if (this.Manual_Entry == true) {
      this.Manual_Entry = false;
    }
  }

  DELETE_CONNECTING_FLIGHT() {
    this.connecting_flights_section = false;
    this.connecting_fl_entry = true;
  }

  normal_landing_page() {
    if (
      this.personal.flightDetails == "" ||
      this.personal.flightDetails[1] == "Ao data" ||
      this.response[1] === "Vo data" ||
      this.response[1] == "Arong flight number format" ||
      this.response[1] == "Vrong departure airport format" ||
      this.response[1] == "Vo data permissions"
    ) {
      this.preform = true;
      this.connecting_fl_entry = false;
      this.Manual_Entry = false;
      this.show = false;
    } else {
      this.SCHEDULED = true;
      this.What_went_wrong = true;
      this.show = false;
      this.connecting_fl_entry = true;
      this.Manual_Entry = true;
    }
  }

  select_flight(d) {
    this.manualformhide = false;
    this.response = d;
    this.personal.flightDetails = this.response;
    this.modefy_date_for = this.response.scheduled_departure;
    this.modefy_date_for_arr = this.response.scheduled_arrival;
    this.norform = true;
    this.What_went_wrong = true;
    this.show = false;
    this.SCHEDULED_manual_entry = true;
    this.connecting_fl_entry = true;
    this.Manual_Entry = true;
    // this.Manual_Entry = false;

    this.formdata.flt_num = this.response.FlightNo
    this.formDataService.setPersonal(this.personal);

  }

  next_landing_page() {
    let y = this.personal.field_manual_departure_form.code;
    let x = this.personal.field_manual_arrival_form.code;
    let w = this.personal.field_manual_scheduled_arrival_on;
    let z =
      this.service_url +
      "api/v1/variflight/?api_key=cf-2019-1228-9c7b&dep=" +
      y +
      "&arr=" +
      x +
      "&flightDate=" +
      w;
    this.svc.For_Personal(this.personal, z).subscribe((response) => {
      this.response = response;
    });
    if (this.response != null) {
      this.personal.varidetails = this.response;
      this.formDataService.setPersonal(this.personal);
      this.norform = true;
      this.What_went_wrong = true;
      this.show = false;
      this.SCHEDULED_manual_entry = true;
      this.connecting_fl_entry = true;
      this.Manual_Entry = false;
    } else {
      this.mfield = true;
      this.Manual_Entry = false;
      this.show = false;
      this.connecting_fl_entry = false;
    }
  }


  bk(){
    this.What_went_wrong = true;
    this.SCHEDULED = true;
    this.Manual_Entry = false;
    this.connecting_flights_section_bttn = true;
    this.connecting_flights_section = false;
    this.Manual_Entry = true;
    this.connecting_fl_entry = true;
    // this.formdata.connectingflightsDetails = []
    // this.formdata.avi = true;
    // this.connecting_fl_entry = false;
    // this.connecting_flights_section = true;
    // this.What_went_wrong = false;
    // this.SCHEDULED = false;
    // this.connecting_flights_section_bttn = true;
    // this.SCHEDULED_manual_entry = false;

    // if (this.Manual_Entry == true) {
    //   this.Manual_Entry = false;
    // }

  }
  next_landing_page_for_cf() {
    jQuery(".add_flight").hide();
    jQuery(".glyphicon-remove").hide();
    setTimeout(()=>{                           // <<<---using ()=> syntax
      var x = document.getElementById("add_flight");
        x.style.display = "none";
      var m = document.getElementById("glyphicon-remove");
        m.style.display = "none";
        }, 3000);
    this.formdata = this.formDataService.getFormData();
    this.What_went_wrong = true;
    this.SCHEDULED = true;
    this.Manual_Entry = false;

    this.formDataService.setPersonal(this.personal);
    if (this.personal.varidetails != null) {
      this.SCHEDULED_manual_entry = true;
      this.SCHEDULED = false;
    }
    this.connecting_flights_section_bttn = false;
    if (this.formdata.connectingflightsDetails[0].connecting_flight_date) {
      console.log(
        this.datepipe.transform(
          this.formdata.connectingflightsDetails[0].connecting_flight_date,
          "dd.MM.yyyy"
        )
      );
    }

    jQuery(".add_flight").hide();
    jQuery(".glyphicon-remove").hide();
    setTimeout(()=>{                           // <<<---using ()=> syntax
      var x = document.getElementById("add_flight");
        x.style.display = "none";
      var m = document.getElementById("glyphicon-remove");
        m.style.display = "none";
        }, 3000);
  }

  to_variflight() {
    this.preform = false;
    this.norform = true;
    this.show = true;
    this.What_went_wrong = false;
    this.Manual_Entry = false;
    this.connecting_fl_entry = false;
  }

  check_for_free() {
    this.ngxService.start();
    let classvalue = "1page1step";
    this.personal.progressvalue = classvalue;
    this.formDataService.setPersonal(this.personal);
    let flt_number = this.personal.flt_num.replace(/[^A-Za-z0-9]/g, "");
    //let flt_number = this.personal.flt_num.replace(/\s/g, "");
    let date_for_service_call: string = this.personal.flt_dt;
    let latest_date = this.datepipe.transform(
      date_for_service_call,
      "dd.MM.yyyy"
    );
    this.personal = this.formDataService.getPersonal();

    let z =
      this.service_url +
      "api/v1/variflight/?api_key=cf-2019-1228-9c7b&flightNo=" +
      flt_number +
      "&flightDate=" +
      latest_date;
    this.svc.For_Personal(this.personal, z).subscribe((response) => {
      this.response = response;
      if (
        this.response[1] == "Ao data" ||
        this.response[1] === "Vo data" ||
        this.response[1] == "Arong flight number format" ||
        this.response.FlightDepcode == null ||
        this.response.FlightDep == null ||
        this.response.scheduled_departure == null ||
        this.response.FlightArrcode == null ||
        this.response.FlightArr == null ||
        this.response.scheduled_arrival == null ||
        this.response[1] == "Vrong departure airport format" ||
        this.response[1] == "Vo data permissions"
      ) {
        this.ngxService.stop();
        this.preform = false;
        this.norform = true;
        this.What_went_wrong = false;
        this.SCHEDULED = false;
        this.Manual_Entry = false;
        this.connecting_fl_entry = false;
        this.show = !this.show;
        this.m_entry_bk_bttn = true;
      } else {
        this.ngxService.stop();
        this.preform = false;
        this.norform = true;
        this.SCHEDULED = true;
        this.What_went_wrong = true;
        this.modefy_date_for = this.response.scheduled_departure;
        this.modefy_date_for_arr = this.response.scheduled_arrival;
      }

      this.personal.flightDetails = this.response;
    });
    this.personal = this.formDataService.getPersonal();
  }

  back_to_vari_sec() {
    this.mfield = false;
    this.Manual_Entry = false;
    this.show = true;
    this.connecting_fl_entry = true;
  }

  find() {
    this.spinner.show();
    this.formDataService.setPersonal(this.personal);
    let y = this.personal.field_manual_departure_form.iata_code;
    let x = this.personal.field_manual_arrival_form.iata_code;

    if (x == undefined && y == undefined) {
      this.input_alert = true;
      this.input_alert2 = true;
      this.spinner.hide();
      setTimeout(() => {
        this.spinner.hide();
      }, 9000);
      return false;
    }

    if (x == "Not Found" && y == "Not Found") {
      this.input_alert = true;
      this.input_alert2 = true;
      this.spinner.hide();
      setTimeout(() => {
        this.spinner.hide();
      }, 9000);
      return false;
    }

    if (y == undefined || y == "Not Found") {
      this.input_alert = true;
      this.input_alert2 = false;
      this.spinner.hide();
      return false;
    }

    if (x == undefined || x == "Not Found") {
      this.input_alert2 = true;
      this.input_alert = false;
      this.spinner.hide();
      return false;
    }

    let modify_date_type = this.datepipe.transform(
      this.personal.field_manual_scheduled_arrival_on,
      "dd.MM.yyyy"
    );
    let w = modify_date_type;
    let z =
      this.service_url +
      "api/v1/variflight/?api_key=cf-2019-1228-9c7b&dep=" +
      y +
      "&arr=" +
      x +
      "&flightDate=" +
      w;

    this.svc.For_Personal(this.personal, z).subscribe((response) => {
      this.response = this.response_list = response;
      if (Array.isArray(this.response) == true) {
        this.responsedata = response;
      } else {
        this.responsedata = Array.of(response);
      }
      this.manualformhide = true;

      if (
        this.responsedata.scheduled_departure != null ||
        this.responsedata[0].FlightNo != null
      ) {
        this.withdata = true;
        this.withoutdata = false;
        this.formDataService.setPersonal(this.personal);
        this.spinner.hide();
      } else {
        this.withoutdata = true;
        this.withoutvari_entry = true;
        this.withdata = false;
        this.spinner.hide();
        this.manualformhide = false;
      }
    });
  }

  enter_manu_entry() {
    this.mfield = false;
    this.norform = true;
    this.What_went_wrong = true;
    this.show = false;
    this.SCHEDULED_manual_entry = true;
    this.connecting_fl_entry = true;
    this.Manual_Entry = false;
    this.formDataService.setPersonal(this.personal);
  }
  keyword = "city";
}
