import { Component, OnInit } from "@angular/core";
import { FormData } from "../data/formData.model";
import { FormDataService } from "../data/formData.service";
@Component({
  selector: "app-footer-section",
  templateUrl: "./footer-section.component.html",
  styleUrls: ["./footer-section.component.css"],
})
export class FooterSectionComponent implements OnInit {
  constructor(private formDataService: FormDataService) {
    this.formdata = this.formDataService.getFormData();
  }
  formdata: FormData;

  ngOnInit() {}
}
